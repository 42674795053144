import React, { useState } from 'react';
import closeIcon from '../src/icons/close.png';

const MonthRangePicker = ({ onClose, onContinue, fromDate }) => {

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const years = Array.from({ length: fromDate ? (currentYear - Number(new Date(fromDate).getFullYear()) + 1) : currentYear - 2016 + 1 }, (_, i) => currentYear - i);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [selectedYear, setSelectedYear] = useState(years[0]);
    const [startMonth, setStartMonth] = useState(null);
    const [endMonth, setEndMonth] = useState(null);

    const handleMonthClick = (monthIndex) => {
        if (startMonth === null) {
            setStartMonth(monthIndex);
        } else if (startMonth !== null) {
            if (startMonth !== null && endMonth !== null) {
                // setStartMonth(monthIndex);
                // setEndMonth(null);

                if (monthIndex > startMonth) {
                    setEndMonth(monthIndex);
                } else if (monthIndex < startMonth) {
                    setStartMonth(monthIndex);
                    // setEndMonth(startMonth);
                }
            }
            else if (startMonth !== null && endMonth === null) {
                if (monthIndex > startMonth) {
                    setEndMonth(monthIndex);
                } else if (monthIndex < startMonth) {
                    setStartMonth(monthIndex);
                    setEndMonth(startMonth);
                }
            }
        }
    };

    const isFutureMonth = (month, selectedMonthIndex) => {

        if (selectedYear > currentYear) {
            return true;
        }

        if (selectedYear === currentYear && selectedMonthIndex > currentMonth) {
            return true;
        }

        return false;
    };

    const isInRange = (index) => {
        return startMonth !== null && endMonth !== null && index >= startMonth && index <= endMonth;
    };

    const getDateRange = () => {
        let startDate, endDate;

        if (startMonth !== null && endMonth !== null) {
            startDate = new Date(selectedYear, startMonth, 1, 0, 0, 0);

            if (currentMonth == endMonth) endDate = new Date();
            else endDate = new Date(selectedYear, endMonth + 1, 0, 23, 59, 59);

            return {
                startDate: startDate,
                endDate: endDate,
            };
        } else if (startMonth !== null) {

            startDate = new Date(selectedYear, startMonth, 1, 0, 0, 0);

            if (currentMonth == startMonth) endDate = new Date();
            else endDate = new Date(selectedYear, startMonth + 1, 0, 23, 59, 59);

            return {
                startDate: startDate,
                endDate: endDate,
            };
        }
        return null;
    };

    const handleContinueClick = () => {
        const range = getDateRange();
        if (range) {
            onContinue({ frmDate: range.startDate, toDate: range.endDate })
        } else {
            console.log('Please select a range of two months.');
        }
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "99%",
            width: "100%",
            position: "absolute"
        }}>
            <div style={{ position: "relative", width: "23%", height: "99%", background: "#f5f5f5" }}>
                <div style={{ fontSize: "16px", fontWeight: "500", display: "flex", justifyContent: "left", alignItems: "center", padding: "10px", }}>Year</div>
                <div style={{ overflow: "auto", display: "flex", flexDirection: "column", gap: "4px", height: "85%", padding: "0px 30px 0 10px" }}>
                    {years.map((year) => (
                        <div
                            key={year}
                            onClick={() => setSelectedYear(year)}
                            style={{
                                fontSize: "13px",
                                fontWeight: "500",
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                backgroundColor: selectedYear === year ? '#3988F91A' : '',
                                color: selectedYear === year ? '#3988F9' : '#68717E',
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '15px',
                                padding: "0 0 0 15px"
                            }}
                        >
                            {year}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ padding: "10px 20px", position: "relative", width: "77%", height: "100%", boxShadow: "-11px 0px 18px -14px #bbbbbb" }}>

                <div style={{ position: "relative", width: "100%", height: "90%", display: "flex", flexDirection: "column", justifyContent: "left", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ fontSize: "16px", fontWeight: "500", }}>Month<span style={{ fontSize: "9px", color: "#68717E" }}>( Click to select multiple months )</span> </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>

                            <img src={closeIcon} style={{ height: "12px", cursor: "pointer" }} onClick={onClose} />
                        </div>
                    </div>
                    <div style={{ padding: "30px 20px", display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px', width: "100%", height: "100%" }}>
                        {months.map((month, index) => (
                            <div
                                key={month}
                                onClick={() => { if (!isFutureMonth(month, index)) handleMonthClick(index) }}
                                style={{
                                    // padding: '10px',
                                    height: "20px",
                                    width: "40px",
                                    fontSize: "12px",
                                    opacity: isFutureMonth(month, index) ? "0.5" : "1",
                                    backgroundColor:
                                        startMonth === index || endMonth === index || isInRange(index)
                                            ? '#D7E6FC'
                                            : '#fff',
                                    color:
                                        startMonth === index || endMonth === index || isInRange(index)
                                            ? '#081425'
                                            : '#68717E',

                                    border: 'none',
                                    cursor: isFutureMonth(month, index) ? "not-allowed" : 'pointer',
                                    borderRadius: '5px',
                                }}
                            >
                                {month}
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ padding: "0px", position: "relative", width: "100%", height: "10%", display: "flex", justifyContent: "right", gap: "20px", alignItems: "center" }}>
                    <div
                        onClick={() => { setStartMonth(null); setEndMonth(null) }}
                        style={{
                            border: "1px solid rgb(201, 201, 201)",
                            width: "110px",
                            height: "30px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "7px",
                            fontSize: "14px",
                            cursor: "pointer",
                        }} >
                        Reset
                    </div>
                    <div
                        onClick={handleContinueClick}
                        disabled={!startMonth}
                        style={{
                            alignSelf: 'center',
                            backgroundColor: startMonth !== null ? '#007bff' : '#ccc',
                            color: '#fff',
                            border: 'none',
                            cursor: startMonth !== null ? 'pointer' : 'not-allowed',
                            borderRadius: '5px',
                            height: "25px",
                            display: "flex",
                            width: "100px",
                            fontSize: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        Continue
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MonthRangePicker;
