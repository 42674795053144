import React, { useEffect, useState } from 'react'
import LoaderComponent from '../ReusableComponents/LoaderComponent';
import { getLimitedName, Popup } from '../ReusableComponents/reusableFunctions';
import { deleteEmailFromSettings, getAllEmailsWithSettings, updateEmailSettings } from '../api';
import AddNewEmailPopup from './AddNewEmailPopup';
import DeleteIcon from "../Icon/dustbin.svg"
const EmailSettings = ({ user, type }) => {
    const [loading, setLoading] = useState(true)
    const [emails, setEmails] = useState([])
    const handleOnChangeSetting = (id, name, value) => {
        const newSettings = emails.map(e => {
            if (e.id == id) return { ...e, [name]: value };
            else return e;
        });
        setEmails(newSettings)
    }
    const getAllEmailsWithSettingsFunc = async () => {
        try {
            const emails = await getAllEmailsWithSettings();
            setEmails(emails)
            setLoading(false)
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e.message || "Something went wrong while getting emails")
        }
    }

    const updateEmailSettingsFunc = async () => {
        Popup.alert("WAIT", "Updating settings, please wait...")
        try {
            await updateEmailSettings(emails);
            Popup.alert("COMPLETE", "Settings updated successfully")
            setLoading(false)
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e.message || "Something went wrong while updating settings")
        }
    }

    const handleDeleteEmail = async (id) => {
        Popup.alert("WAIT", "Deleting email, please wait...")
        try {
            const deletedId = await deleteEmailFromSettings(id);
            setEmails(prev => prev.filter(e => e.id !== deletedId))
            Popup.alert("COMPLETE", "Email deleted successfully")
            setLoading(false)
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e.message || "Something went wrong while deleting email from settings")
        }
    }
    useEffect(() => {
        getAllEmailsWithSettingsFunc()
    }, [])
    return (
        user?.username ? <div className="wrapper">

            <div className='setting-containt' style={{ display: 'flex', flexDirection: 'column' }} >
                <div style={{ display: "flex", width: '100%', justifyContent: "right", alignItems: "center" }}>
                    <AddNewEmailPopup
                        addEmailCallback={(email) => {
                            setEmails(prev => ([...prev, email]))
                        }}
                        user={user}
                    />
                </div>
                <div className='scroll-container'
                    style={{ overflow: 'auto', marginTop: '20px', height: '90%', border: 'none', borderRadius: '8px' }}
                >
                    {loading ?
                        <LoaderComponent />
                        : <table className="customers-table" style={{ marginTop: '0px' }}>
                            <col style={{ width: '50%' }} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            <col style={{ width: '16.66%' }} />
                            {emails.length > 0 ? <>
                                <tr className="customers-table-header-row" style={{ background: "#E1EDFF" }}>
                                    <th>Email</th>
                                    <th style={{ textAlign: 'center' }}>Drone Health Report</th>
                                    <th style={{ textAlign: 'center' }}>Weekly Report</th>
                                    <th style={{ textAlign: 'center' }}>Software Update</th>
                                    <th style={{ textAlign: 'center' }}>Error logs</th>
                                    <th style={{ textAlign: 'center' }}>Action</th>
                                </tr>
                                {emails.map((keyData =>
                                    <tr className="customers-table-data-row">
                                        <td>{keyData.email}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                <input
                                                    type='checkbox'
                                                    style={{ cursor: "pointer" }}
                                                    name='drone_health_report'
                                                    onChange={(e) => handleOnChangeSetting(keyData.id, e.target.name, e.target.checked)}
                                                    checked={keyData.drone_health_report} />
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                <input
                                                    type='checkbox'
                                                    style={{ cursor: "pointer" }}
                                                    name='weekly_report'
                                                    onChange={(e) => handleOnChangeSetting(keyData.id, e.target.name, e.target.checked)}
                                                    checked={keyData.weekly_report} />
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                <input
                                                    name='software_version_update'
                                                    type='checkbox'
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => handleOnChangeSetting(keyData.id, e.target.name, e.target.checked)}
                                                    checked={keyData.software_version_update} />
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                <input
                                                    name='crashlytics'
                                                    type='checkbox'
                                                    style={{ cursor: "pointer" }}
                                                    onChange={(e) => handleOnChangeSetting(keyData.id, e.target.name, e.target.checked)}
                                                    checked={keyData.crashlytics} />
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }} >
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={DeleteIcon} style={{ width: "16px", height: "16px", cursor: 'pointer' }} onClick={() => {
                                                    Popup.alert("WARNING", "Are you sure, you want to delete this email?", () => handleDeleteEmail(keyData.id))
                                                }} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </>
                                : <tr className="customers-table-data-row">
                                    <td colspan="7" style={{ textAlign: "center", }}>No Emails Found</td>
                                </tr>}
                        </table>}
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {emails.length > 0 && <div onClick={updateEmailSettingsFunc} style={{
                        fontSize: '11px',
                        fontWeight: '500',
                        color: 'white',
                        backgroundColor: '#2989cf',
                        display: 'flex',
                        width: '100px',
                        height: '25px',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}>Update</div>}
                </div>

            </div>
        </div >
            : <></>
    )
}

export default EmailSettings