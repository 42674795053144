import React, { useState, useEffect } from 'react';
import { getAllUnregisteredDrone } from './api.js';
import Map from './MapComponent';

const UnregisteredDrones = () => {

    const [drones, setDrones] = useState([])
    const [loading, setLoading] = useState(true)

    const getDroneLocations = async () => {
        let drones = await getAllUnregisteredDrone();
        setDrones(await drones.allUnregisteredDrones.map(drn => {
            return { ...drn, latLng: `${drn.lat}, ${drn.lng}`, tele_data: { lat: drn.lat, lon: drn.lng } }
        }))
        setLoading(false)
    }

    useEffect(() => { getDroneLocations(); }, [])

    return (
        <div className="wrapper">
            <div className="right-content-new" style={{ zIndex: 0 }}>
                <div className="top-bar-new" style={{ display: "flex" }}>
                    <div className="top-bar-text-new">Unregister Drones Location ({drones.length})</div>
                </div>
                <div className='main-content-new' style={{ height: 'calc(100% - 55px)', padding: "0px" }}>
                    {loading ? <div /> : <Map
                        className="dcc-all-drones-map"
                        recenterMap={true}
                        locationsData={drones}
                        OEMDroneShowTooltip={true}
                        markerClickCallback={(drone) => {
                            // props.history.push('/admin/drone_flight_locations', {
                            //     user: user,
                            //     drone: drone
                            // });
                        }}
                        showIcons={true}
                        includePortsInRecenter={true}
                    />}
                </div>
            </div>
        </div>
    )
}

export default UnregisteredDrones;