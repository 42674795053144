import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { generateFlightsWeeklyReport, getUserType, getWeeklyFlightReportData } from '../../api'
import { GCS_FEATURES, getFeaturesPermissions } from '../../Teams'
import { checkSubscriptionAndStorage, getWeeksInMonth, Popup } from '../../ReusableComponents/reusableFunctions'
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs'
import DatePicker from "react-datepicker";
import LoaderComponent from '../../ReusableComponents/LoaderComponent'
import InfiniteScroll from 'react-infinite-scroll-component'

import "./WeeklyReport.css"
import WeekTile from './WeekTile'
import AppConstants from '../../AppConstants'
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const CustomDatePicker = forwardRef(({ value, onClick, className }, ref) => {
    return <button className={className} ref={ref} onClick={onClick}>
        {value}
    </button>
})
const WeeklyReport = (props) => {

    const [loading, setLoading] = useState(true)
    const [permissions, setPermissions] = useState(null)
    const [user, setUser] = useState(null)
    const [selectedMonthDate, setSelectedMonthDate] = useState(new Date())
    const [showSubscriptionExpirePopup, setShowSubscriptionExpirePopup] = useState(undefined)

    const initData = async () => {
        try {
            if (!user) {
                const user = props?.location?.state?.user
                const permissions = await getFeaturesPermissions([GCS_FEATURES.PLANS])
                setPermissions(permissions)
                if (user) {
                    setUser(user)
                } else {
                    const [blah, type, user] = await getUserType()
                    setUser(user)
                }
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
            Popup.alert("ERROR", err.message || "Something went wrong while loading page")
        }
    }





    const [months, setMonths] = useState([]);
    const [currentYear, setCurrentYear] = useState(dayjs().year());
    const [currentMonth, setCurrentMonth] = useState(dayjs().month());
    const [hasMore, setHasMore] = useState(true);

    const loadInitialMonths = (month, year) => {
        const minMonth = dayjs(user.created_on).month();
        const minYear = dayjs(user.created_on).year();
        const initialMonths = [];
        for (let i = 0; i < 5; i++) {
            if (year < minYear || (year === minYear && month < minMonth)) {
                setMonths(initialMonths);
                setCurrentMonth(month);
                setCurrentYear(year);
                return; // Stop loading if we have reached the month of user.create_on

            }
            if (i == 0) {
                setSelectedMonthDate(new Date(year, month))
            }

            initialMonths.push({ month, year, weeks: getWeeksInMonth(month, year, user.created_on) });
            month -= 1;
            if (month < 0) {
                month = 11;
                year -= 1;
            }

        }
        setMonths(initialMonths);
        setCurrentMonth(month);
        setCurrentYear(year);
    };

    useEffect(() => {
        if (user) {

            loadInitialMonths(currentMonth, currentYear);
        }
    }, [user]);


    const loadMoreMonths = () => {
        if (!user) return;
        const newMonths = [];
        let month = currentMonth;
        let year = currentYear;
        const minMonth = dayjs(user.created_on).month();
        const minYear = dayjs(user.created_on).year();

        for (let i = 0; i < 5; i++) {
            if (year < minYear || (year === minYear && month < minMonth)) {
                setMonths((prevMonths) => [...prevMonths, ...newMonths]);
                setCurrentMonth(month);
                setCurrentYear(year);
                return; // Stop loading if we have reached the month of user.create_on

            }
            newMonths.push({
                month,
                year,
                weeks: getWeeksInMonth(month, year, user.created_on),
            });
            month -= 1;
            if (month < 0) {
                month = 11;
                year -= 1;
            }
        }

        setMonths((prevMonths) => [...prevMonths, ...newMonths]);
        setCurrentMonth(month);
        setCurrentYear(year);
    };

    const scrollToTop = () => {
        const scrollContainer = document.getElementById('scroll-container-weekly-report');
        if (scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }
    const handleMonthYearChange = (selectedDate) => {
        selectedDate = new Date(selectedDate)
        const selectedMonth = selectedDate.getMonth(); // Extract the month (0-11)
        const selectedYear = selectedDate.getFullYear(); // Extract the year
        // Clear existing months and load new data starting from the selected month and year
        setMonths([]);
        loadInitialMonths(selectedMonth, selectedYear);

    };

    const handleWeekClick = async (week) => {
        try {
            Popup.alert("WAIT", 'Generating pdf, please wait...');

            // Use Day.js to handle the dates and convert them to UTC ISO strings
            const startDate = week.start.utc().toISOString();
            const endDate = week.end.utc().toISOString();


            // Pass the ISO strings directly to your API function
            await generateFlightsWeeklyReport(startDate, endDate);
            Popup.close();
        } catch (e) {
            console.log(e);
            Popup.alert('ERROR', e.message || e);
        }
    };

    useEffect(() => {
        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
            console.log(data);
            if (data.error || data.storageExceed || data.isExpired || data.subscriptionExpire || !data.isValidStoragePlan) setShowSubscriptionExpirePopup({ ...data, user: user });
            else setShowSubscriptionExpirePopup(false)
        })
        initData()
    }, [])
    return (
        <div className='wrapper'>
            <div
                className="right-content-new"
            >

                {showSubscriptionExpirePopup ?
                    <SubscriptionExpireModal
                        data={showSubscriptionExpirePopup}
                    /> : <></>
                }
                <div className="top-bar-new">
                    <div className="top-bar-text-new">
                        <BreadCrumbs
                            data={[
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user
                                    }
                                },

                                {
                                    displayName: 'Weekly Report',
                                }
                            ]} />
                    </div>
                </div>
                <div className='main-content-new' style={{ paddingTop: "20px" }}>
                    {
                        loading ? <LoaderComponent />
                            :
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: "20px 0px" }}>
                                    <div style={{ fontWeight: '600', fontSize: '25px', marginRight: 'auto' }}>
                                        Weekly Report
                                    </div>
                                    <div>
                                        {/* {new Date(selectedMonthDate) !== (new Date(new Date().getFullYear(), new Date().getMonth())) && <span style={{ color: "#2989cf", cursor: 'pointer' }} onClick={() => {
                                            const currY = dayjs().year()
                                            const curM = dayjs().month()
                                            loadInitialMonths(curM, currY);
                                        }}>Today</span>} */}
                                        <DatePicker
                                            onSelect={scrollToTop}
                                            selected={new Date(selectedMonthDate)}
                                            onChange={handleMonthYearChange}
                                            customInput={<CustomDatePicker className="custom-datepicker-input" />}
                                            minDate={new Date(user.created_on)} // Set the minimum date to the user's creation date
                                            maxDate={new Date()} // Set the maximum date to today
                                            showMonthYearPicker
                                        />

                                    </div>
                                </div>
                                <div id="scroll-container-weekly-report">
                                    <InfiniteScroll
                                        style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingRight: '20px' }}
                                        dataLength={months.length}
                                        next={loadMoreMonths}
                                        hasMore={hasMore}
                                        scrollableTarget="scroll-container-weekly-report"
                                    >
                                        {months.map(({ month, year, weeks }, index) => (
                                            <div id='scroll-item-weekly-report' >
                                                <div style={{ fontSize: '22px', fontWeight: 600, borderBottom: '4px solid #EEEEEA', padding: '15px 30px' }}>{dayjs().month(month).year(year).format('MMMM YYYY')}</div>
                                                <div className="weeks-grid">
                                                    {weeks.map((week, weekIndex) => (
                                                        <WeekTile key={weekIndex}
                                                            weekIndex={weekIndex}
                                                            week={week}
                                                            handleWeekClick={handleWeekClick}
                                                        />
                                                    ))}
                                                </div>
                                            </div>

                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div >

    )
}

export default WeeklyReport