import L from "leaflet";
import dateFormat from 'dateformat'
import next from "./icons/next.png";
import prev from "./icons/prev.png";
import closeButton from "./close-button.png";
import waiting from "./res/waiting.png";
import Popup from "reactjs-popup";
import React, { Component, useEffect } from "react";
import { addProjectDetails } from "./redux";
import * as API from "./api.js";
import "./style.css";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import inside from "point-in-polygon";
import { Link, StaticRouter, Redirect } from "react-router-dom";
import Geocode from "react-geocode";
import * as htmlToImage from "html-to-image";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import roadMapView from './assets/Images/roadMapViewzz.png'
import sateliteView from './assets/Images/sateliteView.png'
import getArrows from "./ReusableComponents/ArrowOnPolyline";
import {
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
import { addPlan } from "./redux";
import SingleMap from "./ReusableComponents/map";
import ButtonWithIcon from "./ReusableComponents/ButtonWithIcon";
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from "./Teams";
import AppConstants, { MAP_URLS } from "./AppConstants";
import LoaderComponent from "./ReusableComponents/LoaderComponent";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import { addUserAnalytic, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");
const today = new Date();
const time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var endTime =
  today.getHours() + 1 + ":" + today.getMinutes() + ":" + today.getSeconds();
var latLongList = {
  waypoints: []
}; // array used for insert list of waypoints with parameters
var gmarkers = [];
var gpoly = [];
var wayPoints = [];
var poly;
var polyPath = [];
var path = [];
var testList = [];
var waypointLocation = [];
//arrays to store geofence data
var latLongList1 = {}; // array used for insert list of geofence with parameters
var gmarkers1 = [];
var gpoly1 = [];
var waypointLocations = [];
var wayPoints1 = [];
var poly1;
var polyPath1 = [];
var path1 = [];
let planColor = '#2989cf'
let fenceColor = 'white'
var arrows = []
var toggle = false;
var someDate = new Date();
someDate.setDate(someDate.getDate());
var date = someDate.toISOString().substr(0, 10);

class RMFlightPlan extends Component {
  constructor(props) {
    super(props);
    // this.initMap = this.initMap.bind(this); //initmap binded in contructor to call initmap at the loading time of component
    this.state = {
      isLoadingPlan: true,
      prevMissionPoints: [],
      prevFencePoints: [],
      editPlan: false,
      fence: false,
      clearCount: 0,
      gmarkers: [],
      gmarkers1: [],
      gpoly: [],
      gpoly1: [],
      redirectToCreatePlan: false,
      user: {},
      videoJsOptions: null,
      locations: "nashik",
      streamKeys: this.props.streamKeys,
      topics: this.props.topics,
      center: this.props.location.state.center ? this.props.location.state.center : { lat: 20.008482, lng: 73.756834 },
      zoom: 12,
      activeMarker: {},
      selectedPlace: {},
      selectedText: {},
      selectedId: {},
      waypoints: [],
      waypoints1: [],
      markerInitialize: false,
      markerInitialize1: false,
      showMissionPopup: false,
      showFencePopup: false,
      showProjectPopup: false,
      showWaypointValidationPopup: false,
      showWaypointValidationPopup1: false,
      currentPoint: 1,
      currentPoint1: 1,
      currentpage: 1,
      userperpage: 5,
      lastWaypointLocation: {},
      lastWaypointLocation1: {},
      circleStrokeColor: "orange",
      circleFillColor: "orange",
      altitude: "",
      speed: "",
      planEmptyModal: false,
      speed1: "",
      Distance: 0,
      waypointsStyle: [],
      waypointsStyle1: [
        {
          height: "25px",
          width: "25px",
          marginTop: "8px",
          background: "white",
          border: "1px solid",
          borderColor: "hsl(0, 0%, 93%)",
          outline: "none",
          borderRadius: "21px",
          fontSize: "15px",
          color: "#040404",
          display: "flex",
          marginLeft: "10px",
        },
      ],
      showingInfoWindow: false,
      type: "",
      flightType: "",
      roll: 0,
      pitch: 0,
      yow: 0,
      duration: 0,
      flightDate: date,
      camera: 0,
      gimble: false,
      startTime: time,
      endTime: endTime,
      hold: false,
      toggle: false,
      toggle1: false,
      toggle2: false,
      label: 1,
      label1: 1,
      lattitude: "",
      longitude: "",
      background: "white",
      displayWaypoint: true,
      sidebarMode: this.props.location.state
        ? this.props.location.state.sidebarMode
        : "shrunk",
      redirect: false,
      creatingPlan: false,
      itemName: "",
      showProcessPopup: false,
      processPopupIcon: "",
      processAction: "",
      processMessage: "",
      deleteItem: "",
      processTileIndex: "NDVI",
      processTileMin: -0.283,
      processTileMax: 0.181,
      processTileType: "RGN",
      baseLayer: '',
      activeBaseLayer: 'satelite',
      selectedLayer: 'Orthomosaic',
      layerSelectionPopup: false,
      selectedLayerType: 'orthomosaic',
      layer: '',
      toggleChecked: false,
      loading: true
    };
  }

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  updateHistogram = async () => {
    return new Promise((resolve, reject) => {
      let taskId = this.state.linked_map ? this.state.linked_map : null
      let { processTileIndex, processTileType, processTileMin, processTileMax, user } = this.state
      let sas = encodeURIComponent(this.state.permissions?.st)
      let blobContainer = this.state.permissions?.container
      let algorithm = processTileIndex
      API.getHistogram({ taskId, algorithm, processTileType: processTileType ? processTileType : "RGB", processTileMin, processTileMax, sas, blobContainer }).then((data) => {
        this.setState((state) => ({
          ...state,
          processTileMin: data.statistics[1].percentiles[0].toFixed(4),
          processTileMax: data.statistics[1].percentiles[1].toFixed(4),
          processTileType: processTileType ? this.state.processTileType : data.algorithms[0].filters[0]
        }), () => {
          this.overlayTilesLayerOf("plantHealth")
        })
      })
    })
  }

  // To change base layer of map
  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })
  }

  removeLayer = async (layer) => {
    let removeLayer = this.state[layer]
    this.setState({ [layer]: undefined }, () => {
      if (removeLayer) {
        console.log(removeLayer)
        removeLayer.remove()
      }
    })
  }

  getTilesBounds = async (taskId) => {
    return new Promise((resolve, reject) => {
      let sas = encodeURIComponent(this.state.permissions?.st)
      let blobContainer = this.state.permissions?.container
      console.log(this.state.permissions?.container, this.state.permissions)
      API.getTilesBounds({ taskId, tiffType: "orthomosaic", sas, blobContainer }).then((data) => {
        this.setState({
          tilesBounds: data.tilesBounds,
          bounds: data.bounds,
          //defaultOrtho: true,
          band1: "1",
          band2: "2",
          band3: "3",
        }, () => {
          resolve(this.state.bounds)
        })
      })
    })
  }

  updatePlanStyle = (type) => {
    //-------- Change polyline, arrows, polygon and marker color according to layer --------
    if (this.leafletMarkers && this.leafletMarkers.length > 0)
      this.leafletMarkers.forEach((m, i) => {
        if (i != this.state.currentPoint - 1)
          m.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf', fillColor: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf' })
        else
          m.setStyle({ color: type == 'orthomosaic' || type == 'base' ? '#26a69a' : 'brown', fillColor: type == 'orthomosaic' || type == 'base' ? '#26a69a' : 'brown' })
      });
    if (arrows.length > 1) {
      arrows.forEach(arrow => {
        arrow.remove()
      });
      arrows = getArrows(waypointLocations, type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf', 1, this.state.map)
      arrows.forEach(a =>
        a.addTo(this.state.map))
    }
    if (this.polyline) {
      this.polyline.setStyle({ color: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf', fillColor: type == 'orthomosaic' || type == 'base' ? 'orange' : '#2989cf' })
      if (this.fencePolygon) {
        this.fencePolygon.setStyle({
          color: type == 'orthomosaic' || type == 'base' ? 'red' : 'white'
        })
      }
    }
  }

  overlayTilesLayerOf = async (type) => {
    console.log("Type : ", type);
    let { map, plan, linked_map } = this.state
    this.removeLayer("orthoLayer").then(async () => {
      if (linked_map) {
        this.updatePlanStyle(type)
        let taskid = linked_map
        let { processTileIndex, processTileType, processTileMin, processTileMax, tilesBounds, tileMinZoom, tileMaxZoom } = this.state
        let blobContainer = this.state.permissions?.container;
        let sas = encodeURIComponent(this.state.permissions?.st);
        await this.getTilesBounds(taskid)
        let bounds = [[this.state.bounds[1], this.state.bounds[0]], [this.state.bounds[3], this.state.bounds[2]]]
        console.log(bounds);
        let bandsSelection = `${this.state.RGBShow ? `&bands=${this.state.band1},${this.state.band2},${this.state.band3}` : "&viewDefault=true"}`
        if (type == "plantHealth") {
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/plantHealth/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}&algorithm=${processTileIndex}&min=${processTileMin}&max=${processTileMax}&filterType=${processTileType}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          orthoLayer.on("load", () => {
            console.log("Loaded !!!");
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        } else {
          console.log("in else part...");
          let orthoLayer = L.tileLayer(`${AppConstants.tilesServer}/tiles/${taskid}/${type}/{z}/{x}/{y}.png?sas=${sas}&blobContainer=${blobContainer}${bandsSelection}`, {
            tileSize: 256,
            maxZoom: 28,
            bounds: bounds
          }).addTo(map);
          console.log(orthoLayer);
          orthoLayer.on("load", () => {
            console.log("Loaded !!!");
            this.setState({ mapLoading: false })
          });
          this.setState({ orthoLayer })
        }
      }
    })
  }

  changeSelectedLayer = (layer) => {
    console.log("Layer : ", layer);
    if (layer == 'NDVI') {
      console.log("Here is NDVI");
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "NDVI",
        processTileType: "RGN",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'VARI') {
      console.log("Here is vari");
      this.setState((state) => ({
        selectedLayer: layer,
        layerSelectionPopup: false,
        processTileIndex: "VARI",
        processTileType: "RGB",
        selectedLayerType: 'plantHealth'
      }), () => {
        this.updateHistogram()
      })
    } else if (layer == 'Orthomosaic') {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'orthomosaic'
      })
      this.overlayTilesLayerOf("orthomosaic")
    } else {
      this.setState({
        selectedLayer: layer,
        layerSelectionPopup: false,
        selectedLayerType: 'base'
      })
      this.updatePlanStyle('base')
      this.removeLayer('orthoLayer')
    }
  }

  mapClicked = (e) => {
    if (this.drawing) {
      let lastWayPoint = { lat: e.latlng.lat, lng: e.latlng.lng };
      if (!this.state.toggle && !this.state.toggle1 && !this.state.toggle2) {
        this.setState((state) => ({
          ...state,
          lastWaypointLocation: lastWayPoint,
        }));
        console.log("addcircle called by map click event");
        this.addWayPoint(e)
      }
      // if toggle is true adding grofence points
      else if (this.state.toggle || this.state.toggle1 || this.state.toggle2) {
        this.setState((state) => ({
          ...state,
          lastWaypointLocation1: lastWayPoint,
        }));
        // this.addWayPoint1(e)
      }
    }
  }

  clear = async () => {
    if (arrows.length > 0) {
      arrows.forEach(arrow => {
        arrow.remove()
      });
      waypointLocations = []
      arrows = []
    }
    if (latLongList.waypoints.length > 0) {
      let pPath = await polyPath;
      let waypt = this.state.waypoints;
      pPath.length = 0;
      path = 0;
      polyPath = [];
      latLongList = {
        waypoints: []
      }
      waypointLocation = [];
      this.polyline.remove()
      this.polyline = undefined
      this.markersLayer.remove()
      this.markersLayer = undefined
      this.leafletMarkers = undefined
      this.setState((state) => ({
        ...state,
        label: 1,
        waypoints: [],
        waypointsStyle: [
          {
            height: "25px",
            width: "25px",
            marginTop: "8px",
            background: "white",
            border: "1px solid",
            borderColor: "hsl(0, 0%, 93%)",
            outline: "none",
            borderRadius: "21px",
            fontSize: "15px",
            color: "#040404",
            display: "flex",
            marginLeft: "10px",
          },
        ],
      }));
      wayPoints = [];
    }
  };

  initMap = () => {
    this.clear()
    let { map, center } = this.state
    this.overlayTilesLayerOf('orthomosaic')
    if (this.props.location.state.editPlan) {
      this.drawing = true
      API.getPlan(this.props.location.state.projectName, this.props.location.state.planName).then(
        async (data) => {
          console.log(data)
          if (data.planConfiguration[0]) {
            let resMission = data.Mission;
            let resGeofece = data.Geofence;
            let planStatistics = data.MissionStatistics[0].MissionStatistics
            let fencePolygonData = []
            let latLongListTemp = []
            await resMission.forEach((data) => {
              console.log('waypoint data', data.waypoints);
              let pointdataConverted = {
                alt: data.waypoints.Altitude,
                camera: data.waypoints.Camera,
                delaySeconds: data.waypoints.DelaySeconds,
                delayStatus: data.waypoints.DelayStatus,
                gimbalPitch: data.waypoints.GimbalPitch,
                gimbalRoll: data.waypoints.GimbalRoll,
                gimbalStatus: data.waypoints.GimbalStatus,
                gimbalYaw: data.waypoints.GimbalYaw,
                lat: data.waypoints["MSG x"],
                lon: data.waypoints["Msg y"],
                speed: data.waypoints.Speed
              }
              latLongListTemp.push(pointdataConverted)
              let latlng = {
                lat: data.waypoints["MSG x"],
                lng: data.waypoints["Msg y"]
              }
              this.addWayPoint({ latlng })
            })
            console.log('latLongListTemp', latLongListTemp);
            latLongList.waypoints = latLongListTemp
            await resGeofece.forEach((fence) => {
              fencePolygonData.push({
                lat: fence.geofence["geofence X"],
                lng: fence.geofence["geofence Y"]
              })
            })
            this.fencePolygon = new L.polygon(fencePolygonData, {
              color: 'red',
              fillColor: "black",
              weight: 3,
              fillOpacity: 0.3,
              smoothFactor: 1,
              zIndex: 0
            });
            map.fitBounds(this.fencePolygon.getBounds().pad(0.3));
            this.setState({
              displayWaypoint: true,
              altitude: planStatistics["Mission_Default_Altitude"],
              speed: planStatistics["Mission_Default_Speed"],
              Distance: planStatistics["TotalPlanDistance"]
            })
          } else {
            console.log('new plan')
            let resMission = data.Mission;
            let resGeofece = data.Geofence;
            let planStatistics = data.MissionStatistics.missionStatistics[0]
            let fencePolygonData = []
            let latLongListTemp = []
            await resMission.waypoints.forEach((data) => {
              console.log('waypoint data', data);
              latLongListTemp.push(data)
              let latlng = {
                lat: data.lat,
                lng: data.lon
              }
              this.addWayPoint({ latlng })
            })
            console.log('latLongListTemp', latLongListTemp);
            latLongList.waypoints = latLongListTemp
            await resGeofece.geofence.forEach((fence) => {
              fencePolygonData.push({
                lat: fence.lat,
                lng: fence.lon
              })
            })
            this.fencePolygon = new L.polygon(fencePolygonData, {
              color: 'red',
              fillColor: "black",
              weight: 3,
              fillOpacity: 0.3,
              smoothFactor: 1,
              zIndex: 0
            });
            map.fitBounds(this.fencePolygon.getBounds().pad(0.3));
            this.setState({
              displayWaypoint: true,
              altitude: planStatistics["missionDefaultAltitude"],
              speed: planStatistics["missionDefaultSpeed"],
              Distance: planStatistics["totalPlanDistance"]
            })
          }

        },
        (e) => {
        })
    } else {
      map.setView(center, 16);
      this.drawing = true
      this.addWayPoint({ latlng: center })
    }

  }
  closeActivityPopup = () => {
    this.setState((state) => ({
      ...state,
      showProcessPopup: false,
      processMessage: null
    }))
  }

  onClickWayPoint = (label) => {
    let latlng = {
      lat: latLongList.waypoints[label - 1].lat,
      lng: latLongList.waypoints[label - 1].lon
    }
    let currentpage = Math.floor((label - 1) / this.state.userperpage) + 1;
    this.setState((state) => ({
      ...state,
      altitude: latLongList.waypoints[label - 1].alt,
      speed: latLongList.waypoints[label - 1].speed,
      pitch: latLongList.waypoints[label - 1].gimbalPitch,
      roll: latLongList.waypoints[label - 1].gimbalRoll,
      yow: latLongList.waypoints[label - 1].gimbalYaw,
      currentpage,
    }));
    let temp = this.state.waypointsStyle;
    temp[this.state.currentPoint] = {
      height: "25px",
      width: "25px",
      marginTop: "8px",
      background: "white",
      border: "1px solid",
      borderColor: "hsl(0, 0%, 93%)",
      outline: "none",
      borderRadius: "21px",
      fontSize: "15px",
      color: "#040404",
      display: "flex",
      marginLeft: "10px",
    };
    temp[this.state.currentPoint - 1] = {
      height: "25px",
      width: "25px",
      marginTop: "8px",
      background: "white",
      border: "1px solid",
      borderColor: "hsl(0, 0%, 93%)",
      outline: "none",
      borderRadius: "21px",
      fontSize: "15px",
      color: "#040404",
      display: "flex",
      marginLeft: "10px",
    };
    this.leafletMarkers[this.state.currentPoint - 1].setStyle({
      color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor,
      fillColor: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor,
    })
    this.leafletMarkers[label - 1].setStyle({
      color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? "#26a69a" : 'brown',
      fillColor: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? "#26a69a" : 'brown'
    })
    this.setState((state) => ({
      ...state,
      wayPointsStyle: temp,
      currentPoint: label,
    }));
    let ws = this.state.waypointsStyle;
    ws[label] = {
      height: "25px",
      width: "25px",
      marginTop: "8px",
      background: "#48B0E5",
      border: "1px solid",
      borderColor: "hsl(0, 0%, 93%)",
      outline: "none",
      borderRadius: "21px",
      fontSize: "15px",
      color: "#040404",
      display: "flex",
      marginLeft: "10px",
    };
    this.setState((state) => ({
      ...state,
      lattitude: latlng.lat,
      longitude: latlng.lng,
      wayPointsStyle: ws,
    }));
  }

  addWayPoint = (location) => {
    let { map } = this.state
    const { lat, lng } = location.latlng //destructure only lat,lng
    const latlng = { lat, lng };
    this.setState((state) => ({
      ...state,
      lastWaypointLocation: latlng,
    }));
    waypointLocation.push(latlng);
    let waypt = {
      speed: this.state.speed,
      alt: this.state.altitude,
      camera: this.state.camera,
      cameraAction: 0,
      delaySeconds: this.state.duration,
      delayStatus: this.state.hold,
      gimbalPitch: this.state.pitch,
      gimbalRoll: this.state.roll,
      gimbalStatus: this.state.gimble,
      gimbalYaw: this.state.yow,
      "lat": lat,
      "lon": lng,

    };
    latLongList.waypoints.push(waypt);
    this.polyline = this.polyline ? this.polyline.addLatLng([lat, lng]) : new L.Polyline(waypointLocation, {
      color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor,
      weight: 3,
      opacity: 1,
      smoothFactor: 1,
      zIndex: 1
    }).addTo(this.state.map);
    console.log(this.polyline)
    console.log("way paoint from maps", latLongList)
    let circle = {
      // stroke: this.state.circleStrokeColor,
      color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? "#26a69a" : 'brown',
      fillColor: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? "#26a69a" : 'brown',
      fillOpacity: 1,
      radius: 10
    }
    this.markersLayer = this.markersLayer ? this.markersLayer : new L.layerGroup().addTo(map)
    const label = this.state.label
    let dragMarker = false
    const marker = new L.circleMarker(latlng, circle).bindTooltip(`${label}`,
      {
        className: 'tooltip-label',
        permanent: true,
        direction: 'center',
      }).addTo(this.markersLayer).on('mouseover', (e) => {
        this.drawing = false
      }).on('mouseout', (e) => {
        this.drawing = true
      }).on('click', (e) => {
        this.onClickWayPoint(label)
      }).on('mousedown', (e) => {
        dragMarker = true
        map.dragging.disable()
        map.on("mousemove", (evt) => {
          if (dragMarker) {
            this.setState((state) => ({
              ...state,
              lattitude: evt.latlng.lat,
              longitude: evt.latlng.lng,
              lastWaypointLocation: evt.latlng,
            }));
            waypointLocation[label - 1] = evt.latlng
            this.polyline.setLatLngs(waypointLocation)
            latLongList.waypoints[label - 1] = {
              ...latLongList.waypoints[label - 1],
              "lat": evt.latlng.lat,
              "lon": evt.latlng.lng
            }
            marker.setLatLng(evt.latlng)
            waypointLocations[label - 1] = [evt.latlng.lat, evt.latlng.lng]
            if (arrows.length > 0) {
              if (label == 1) {
                arrows[0].remove()
                let latlngArray = [[evt.latlng.lat, evt.latlng.lng], [waypointLocations[label][0], waypointLocations[label][1]]]
                let arrow = getArrows(latlngArray, this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor, 1, map)
                arrow[0].addTo(map);
                arrows[0] = arrow[0]
              } else if (label == this.state.waypoints.length) {
                let index = arrows.length - 1
                arrows[index].remove()
                let latlngArray = [[waypointLocations[index][0], waypointLocations[index][1]], [evt.latlng.lat, evt.latlng.lng]]
                let arrow = getArrows(latlngArray, this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor, 1, map)
                arrow[0].addTo(map);
                arrows[index] = arrow[0]
              } else {
                let index = label - 1
                arrows[index].remove()
                arrows[index - 1].remove()

                let latlngArray = [[waypointLocations[index - 1][0], waypointLocations[index - 1][1]], [evt.latlng.lat, evt.latlng.lng], [waypointLocations[index + 1][0], waypointLocations[index + 1][1]]]
                let arrowsList = getArrows(latlngArray, this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor, 1, map)
                arrowsList[0].addTo(map);
                arrowsList[1].addTo(map);
                arrows[index - 1] = arrowsList[0]
                arrows[index] = arrowsList[1]
              }
            }
          }
        })
      }).on("mouseup", (evt) => {
        map.dragging.enable()
        dragMarker = false
      })
    wayPoints.push(this.state.label);
    this.setState((state) => ({
      ...state,
      waypoints: wayPoints,
      markerInitialize: true,
      label: this.state.label + 1,
      currentPoint: label
    }), () => {
      let ws = this.state.waypointsStyle;
      ws[label] = {
        height: "25px",
        width: "25px",
        marginTop: "8px",
        background: "#48B0E5",
        border: "1px solid",
        borderColor: "hsl(0, 0%, 93%)",
        outline: "none",
        borderRadius: "21px",
        fontSize: "15px",
        color: "#040404",
        display: "flex",
        marginLeft: "10px",
      };
      this.setState((state) => ({
        ...state,
        lattitude: lat,
        longitude: lng,
        wayPointsStyle: ws,
      }), () => {
        if (this.state.label > 0) {
          ws.forEach((object, idx) => {
            if ((label) != idx) {
              if (idx > 0) {
                this.leafletMarkers[idx - 1].setStyle({
                  color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor,
                  fillColor: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor,
                })
              }
              ws[idx] = {
                height: "25px",
                width: "25px",
                marginTop: "8px",
                background: "white",
                border: "1px solid",
                borderColor: "hsl(0, 0%, 93%)",
                outline: "none",
                borderRadius: "21px",
                fontSize: "15px",
                color: "#040404",
                display: "flex",
                marginLeft: "10px",
              }
              this.setState({ wayPointsStyle: ws })
            }
          })
        }
      });
    });
    if (this.leafletMarkers) {
      this.leafletMarkers.push(marker);
      console.log(waypointLocations[this.state.currentPoint - 2]);
      console.log(waypointLocations);

      let latlngArray = [[waypointLocations[waypointLocations.length - 1][0], waypointLocations[waypointLocations.length - 1][1]], [lat, lng]]
      let arrow = getArrows(latlngArray, this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'orange' : planColor, 1, map)
      arrow[0].addTo(map);
      arrows.push(arrow[0])
      waypointLocations.push([lat, lng])
    }
    else {
      this.leafletMarkers = [marker]
      waypointLocations.push([lat, lng])
    }
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
    }));
  };

  drawFence = () => {
    const { map } = this.state
    let latLngBounds = this.polyline ? this.polyline.getBounds().pad(1) : L.latLngBounds(this.state.center, this.state.center)
    this.polyline ? console.log('polyline avail') : console.log('polyline not avail');
    let center = latLngBounds.getCenter()

    let latlngs = [];

    latlngs.push(latLngBounds.getSouthWest());//bottom left
    latlngs.push(latLngBounds.getSouthEast());//bottom right
    latlngs.push(latLngBounds.getNorthEast());//top right
    latlngs.push(latLngBounds.getNorthWest());//top left

    return new L.polygon(latlngs, {
      color: this.state.selectedLayer === "Orthomosaic" || this.state.selectedLayer === "Base" ? 'red' : "white",
      fillColor: "black",
      weight: 3,
      fillOpacity: 0.3,
      smoothFactor: 1,
      zIndex: 0
    }).addTo(map);
  }

  showFence = () => {
    this.fencePolygon.addTo(this.state.map)
    return this.fencePolygon
  }

  toggleswitch = (e) => {
    this.setState((state) => ({
      ...state,
      toggle: !this.state.toggle,
      fence: !this.state.fence,
    }), () => {
      if (this.state.fence) {
        this.fencePolygon = this.fencePolygon ? this.showFence() : this.drawFence()
        this.fencePolygon.enableEdit()
      } else {
        this.fencePolygon.remove()
      }
    })
  }

  toggleswitchsurvey = (e) => {
    let t = e.target;
    this.setState((state) => ({
      ...state,
      toggle1: !this.state.toggle1,
    }));
    if (this.state.markerInitialize) {
      if (this.state.toggle1) {
        this.draggableTrue();
      } else if (!this.state.toggle1) {
        this.draggableFalse();
      }
    }
  };
  toggleswitchspraying = (e) => {
    let t = e.target;
    this.setState((state) => ({
      ...state,
      toggle2: !this.state.toggle2,
    }));
    if (this.state.markerInitialize) {
      if (this.state.toggle2) {
        this.draggableTrue();
      } else if (!this.state.toggle2) {
        this.draggableFalse();
      }
    }
  };
  prevpage = (event) => {
    event.preventDefault();
    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: this.state.currentpage - 1,
      }));
    }
  };
  nextpage = (event) => {
    event.preventDefault();
    if (
      this.state.currentpage <
      Math.ceil(this.state.waypoints.length / this.state.userperpage)
    ) {
      this.setState((state) => ({
        ...state,
        currentpage: state.currentpage + 1,
      }));
    } else {
    }
  };

  resetFence = () => {
    this.fencePolygon.remove()
    this.fencePolygon = this.drawFence()
    this.fencePolygon.enableEdit()
  }
  clearPlan = () => {
    this.setState((state) => ({
      ...state,
      itemName: "All mission waypoints",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }));
    this.clear();
    this.setState((state) => ({
      ...state,
      itemName: "All mission waypoints",
      processPopupIcon: "COMPLETE",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }));
  };
  clearPlan1 = () => {
    this.setState((state) => ({
      ...state,
      itemName: "All Fence waypoints",
      processPopupIcon: "WAIT",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }));
    this.resetFence();
    this.setState((state) => ({
      ...state,
      itemName: "All Fence waypoints",
      processPopupIcon: "COMPLETE",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }));
  };

  convertToFencePoint = () => {
    let polyPoints = this.fencePolygon.toGeoJSON().geometry.coordinates[0]
    var fencePoints = {
      geofence:
        polyPoints.map((location, key) => {
          return ({
            "lat": location[1],
            "lon": location[0],
            "alt": Number(this.state.altitude),
          })
        })
    }
    return fencePoints;
  }
  // validate waypoints are inside fence or not using inside package
  validateWaypoints = async () => {

    if (this.fencePolygon) {
      console.log('inside fence polygon');
      latLongList1 = await this.convertToFencePoint()
      console.log('latLongList1', latLongList1);

      let polygon = [];
      for (let i = 0; i < latLongList1.geofence.length; i++) {
        console.log('inside loop');
        polygon.push([
          parseFloat(latLongList1.geofence[i].lat),
          parseFloat(latLongList1.geofence[i].lon),
        ]);
      }
      console.log('latLongList', latLongList);
      for (let j = 0; j < latLongList.waypoints.length; j++) {
        let temp = inside(
          [
            parseFloat(latLongList.waypoints[j].lat),
            parseFloat(latLongList.waypoints[j].lon),
          ],
          polygon
        );
        if (!temp) {
          console.log('in temp false part');
          this.setState((state) => ({
            ...state,
            itemName: "Plan",
            processPopupIcon: "ERROR",
            processAction: "ADD",
            processMessage: "Mission is outside geofence area.",
            showProcessPopup: true,
          }));
          return 0
        } else {
          console.log('temp else part');
          if (latLongList.waypoints.length - 1 == j) {
            return 1
          }
        }
      }
    } else {
      console.log('in poly false part');
      this.setState((state) => ({
        ...state,
        itemName: "Plan",
        processPopupIcon: "ERROR",
        processAction: "ADD",
        processMessage: "Please select geofence area.",
        showProcessPopup: true,
      }));
      return 0
    }
  };
  getflightSrc = async () => {
    let lat = latLongList.waypoints[0].lat;
    let lng = latLongList.waypoints[0].lon;

    let add = await Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].address_components[0].short_name;
        // planAddress.flightSource=address
        return address;
        // add = address
      },
      (error) => {
        console.error(error);
      }
    );
    return add;
  };
  resizedataURL = (datas, wantedWidth, wantedHeight) => {
    return new Promise(async function (resolve, reject) {
      // We create an image to receive the Data URI
      var img = document.createElement("img");

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

        var dataURI = canvas.toDataURL();

        // This is the return of the Promise
        resolve(dataURI);
      };

      // We put the Data URI in the image's src attribute
      img.src = datas;
    });
  }; // Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);
  getFlightDest = async () => {
    // let add;
    let length = latLongList.waypoints.length;
    let lat1 = latLongList.waypoints[length - 1].lat;
    let lng1 = latLongList.waypoints[length - 1].lon;
    let add = await Geocode.fromLatLng(lat1, lng1).then(
      (response) => {
        const address = response.results[0].address_components[0].short_name;
        // planAddress.flightDestination=address
        // add = address
        return address;
      },
      (error) => {
        console.error(error);
      }
    );
    return add;
  };
  // get asset reurns the plan map screenshot in base64 format
  getAsset = async () => {
    this.setState((prevState) => ({
      ...prevState,
      creatingPlan: prevState.editPlan ? false : true,
      itemName: "Plan",
      processPopupIcon: "WAIT",
      processAction: prevState.editPlan ? "UPDATE" : "ADD",
      processMessage: null,
      showProcessPopup: true,
    }));
    // take screenshot of plan using html-to-image library
    var newDataUri;
    let a = await htmlToImage
      .toPng(document.getElementById("rmp-map"))
      .then(async (dataUrl) => {
        let newDataUri1;
        newDataUri = await this.resizedataURL(dataUrl, 1080, 720); // reduce the image size
        return newDataUri1;
        // download(dataUrl, 'map.png');
      });
    return newDataUri;
  };

  getStaticstics = () => {
    let stat =
    {
      "missionStatistics": [{
        "ROIwaypointlatitude": 0,
        "ROIwaypointlongitude": 0,
        "landAltLatitue": 0,
        "landAltLongitude": 0,
        "landAltitude": 0,
        "landLatitue": 0,
        "landLongitude": 0,
        "missionDefaultAltitude": Number(this.state.altitude),
        "missionDefaultSpeed": Number(this.state.speed),
        "missionHomeAltitude": 0,
        "missionHomeLatitude": latLongList.waypoints[0].lat,
        "missionHomeLongitude": latLongList.waypoints[0].lon,
        "missionType": 0,
        "planeMaxSpeed": Number(this.state.speed),
        "planeMinSpeed": Number(this.state.speed),
        "surveyCoordCount": 0,
        "takeOffAltitude": 0,
        "takeOffLatitue": 0,
        "takeOffLongitude": 0,
        "totalPlanDistance": Number(this.state.Distance),
        "vehicleType": "QuadCopter",
        "verticalMission": 0,
        "verticalRularScaleMaxValue": 0,
        "wpRadius": 0,
      }]
    }
    return (stat)
  }
  // add plan data in proper format
  getPlan = async () => {
    let inside1 = await this.validateWaypoints(); // call waypoint validation function
    console.log('inside1', inside1);
    if (inside1) {
      let Plan = {};
      let planAddress = {};

      let planStatistics = await this.getStaticstics();

      let flightSrc = await this.getflightSrc();
      let flightDest = await this.getFlightDest();
      planAddress.flightDestination = flightDest;
      planAddress.flightSource = flightSrc;
      let myAsset = await this.getAsset();

      let Assets = {
        "planMap.png": myAsset,
      };
      // let Assets = {
      //   "planMap.png":
      //     "http://167.71.238.142:4173/file/46be9f5a-ede9-41bf-a63f-afe630a5679d.png",
      // };
      let dt = await new Date();
      let date = dt.getDate().toString().padStart(2, "0");
      let month = (dt.getMonth() + 1).toString().padStart(2, "0");
      let year = dt.getFullYear();
      let hour = dt.getHours();
      let minute = dt.getMinutes();
      let seconds = dt.getSeconds();
      let dateTime = await this.props.dateAndTime;

      let planConfiguration = {
        projectDetails: [
          {
            clientName: this.props.clientName,
            'dateTime': new Date().toISOString(),
            flightCompletedCount: 0,
            projectName: this.props.projectName,
            projectId: this.props.location.state.project.id,
            planName: this.props.planName,
            planCompletedStatus: 0,
            softwareVerison: "1.1",
            linkedMap: this.state.linked_map
          }
        ]
      }
      Plan.planConfiguration = await planConfiguration;
      console.log('planConfiguration', planConfiguration);
      Plan.planAddress = await planAddress;
      Plan.missionStatistics = await planStatistics;
      console.log('planStatistics', planStatistics);
      Plan.DGCAfence = await latLongList1;
      console.log('DGCAfence', latLongList1);
      Plan.RallyPointData = [];
      Plan.corridor = [];
      Plan.Mission = await latLongList;
      console.log('Mission', latLongList);
      Plan.Geofence = await latLongList1;
      console.log('Geofence', latLongList1);
      Plan.Assets = await Assets;
      // Plan.Geofence.push(latLongList1.[0]); 
      return Plan;
    }
    // add all details of plan in single JSON object

  };

  drawPlan = () => {
    if (this.props.location.state.projectName != "" &&
      this.props.location.state.planName != "") {
      let dt = new Date();
      let date = dt.getDate().toString().padStart(2, "0");
      let month = (dt.getMonth() + 1).toString().padStart(2, "0");
      let year = dt.getFullYear();
      let hour = dt.getHours();
      let minute = dt.getMinutes();
      let seconds = dt.getSeconds();
      let dateTime = `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
      let project = {
        projectDetails: {
          clientName: "",
          "dateTime": new Date().toISOString(),
          flightCompletedCount: 0,
          projectName: this.props.location.state.projectName,
          softwareVerison: "1.1",
        }
      }
      this.props.addProjectDetails(
        {
          projectName: this.props.location.state.projectName,
          clientName: "",
          planName: this.props.location.state.planName,
          dateAndTime: dateFormat(new Date(), 'mmm dd, yyyy hh:mm:ss ')
        })

      this.setState((state) => ({
        ...state,
        redirectToCreatePlan: true,
      }));
    }
    else {
      alert('All fields are necessary')
    }
  }

  createPlan = async () => {
    let data = {
      gmarkers: latLongList,
      gmarkers1: latLongList1,
    };
    let plan = await this.getPlan();
    // add plan details in central store using react redux
    this.props.addPlan(data);
    // create or edit plan
    if (this.state.editPlan == true) {

      API.editPlan(plan).then(
        (data) => {
          if (data.message == "OK") {
            this.setState((prevState) => ({
              ...prevState,
              itemName: "Plan",
              processPopupIcon: "COMPLETE",
              processAction: "UPDATE",
              processMessage: null,
              showProcessPopup: true,
              redirect: true,
            }));
          } else if (data.message == "Problem with updating plan") {
            //check if plan already exist the show msg in popup
            this.setState((prevState) => ({
              ...prevState,
              itemName: "Plan",
              processPopupIcon: "ERROR",
              processAction: "UPDATE",
              processMessage: data.message,
              showProcessPopup: true,
            }));
          }
        },
        (e) => { }
      );
    } else {
      try {
        // creating actual plan.

        API.createPlan(plan).then(
          async (data) => {
            if (data.message == "OK") {
              this.setState((prevState) => ({
                ...prevState,
                itemName: "Plan",
                processPopupIcon: "COMPLETE",
                processAction: "ADD",
                processMessage: null,
                showProcessPopup: true,
                redirect: true,
                plan: {
                  plan_name: this.props.planName,
                  id: data?.plan?.id
                }
              }));
            } else if (data.message == "Plan already exists") {
              //check if plan already exist the show msg in popup
              this.setState((prevState) => ({
                ...prevState,
                itemName: "Plan",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: "Plan name already exist",
                showProcessPopup: true,
              }));
            } else if (data.error) {
              this.setState((prevState) => ({
                ...prevState,
                itemName: "Plan",
                processPopupIcon: "ERROR",
                processAction: "ADD",
                processMessage: data.error,
                showProcessPopup: true,
              }));
            }
          },
          (e) => { }
        );
      } catch (e) {
        console.log("INSIDE CATCH", e);
      }
    }
  };


  gimbleChange = (e) => {
    latLongList.waypoints[this.state.currentPoint - 1].GimbalStatus =
      !this.state.gimble;
    this.setState((state) => ({
      ...state,
      gimble: !this.state.gimble,
    }));
  };

  holdChange = (e) => {
    latLongList.waypoints[this.state.currentPoint - 1].DelayStatus =
      !this.state.hold;
    this.setState((state) => ({
      ...state,
      hold: !this.state.hold,
    }));
  };

  redirectToCreatePlan = () => {
    let { user, type } = this.state
    if (this.state.redirectToCreatePlan) {
      return <Redirect to={{
        pathname: "/remote_mission/selectLocation",
        state: {
          ...this.state.successParams,
          user,
          type,
          sidebarMode: this.state.sidebarMode,
          drawPlan: true,
          project: this.props.location.state?.project || {}
        }

      }} />
    }
  }
  // redirect to assign drone to plan page after successfull plan creation.
  successRedirectComponent = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/remote_mission/assignDroneToPlan",
            state: {
              gmarkers: this.state.gmarkers,
              gmarkers1: this.state.gmarkers1,
              gpoly: this.state.gpoly,
              gpoly1: this.state.gpoly1,
              user: this.state.user,
              type: this.state.type,
              center: this.state.center,
              plan: {
                id: this.props.location?.state?.plan?.id || this.state.plan?.id,
                plan_name: this.props.location?.state?.plan?.plan_name || this.state.plan?.plan_name,
                project_name: this.props.location?.state?.project?.project_name
              },
              project: {
                id: this.props.location?.state?.project ? (this.props.location.state.project.project_id || this.props.location.state.project.id) : {},
                project_name: this.props.location?.state?.project?.project_name
              },
              sidebarMode: this.state.sidebarMode,
            },
          }}
        />
      );
    }
  };

  onChangeListener = (e) => {
    let t = e.target;
  };

  componentWillUnmount() {
    latLongList = {
      waypoints: []
    }; // array used for insert list of waypoints with parameters
    gmarkers = [];
    gpoly = [];
    wayPoints = [];
    poly = null;
    polyPath = [];
    path = [];
    testList = [];
    waypointLocation = [];
    //arrays to store geofence data
    latLongList1 = {}; // array used for insert list of geofence with parameters
    gmarkers1 = [];
    gpoly1 = [];
    wayPoints1 = [];
    poly1 = null;
    polyPath1 = [];
    path1 = [];
    updateOutTime(this.state.outId)
  }

  initData = () => {
    let state = this.props.location.state;
    if (!this.props.location.state) {
      API.getUserType().then(
        ([registered, type, user]) => {
          // if promise is resolved, update state and redirect user
          this.setState((state) => ({
            ...state,
            loading: false,
            registered,
            type,
            user,
          }));
        },
        (e) => {
          // if promise is rejected, log the error, and display message to user
          this.setState({
            user: {},
            loading: false,
            type: "unknown",
          });
          window.location.replace("/login");
        }
      );
    } else {
      if (this.props.location.state.config) {
        this.setState((state) => ({
          ...state,
          user: this.props.location.state.config.user,
          loading: false,
          type: this.props.location.state.config.type,
        }));
      } else if (this.props.location.state.user) {
        this.setState((state) => ({
          ...state,
          user: this.props.location.state.user,
          loading: false,
          type: this.props.location.state.type,
        }));
      }
    }
  }

  componentDidMount() {
    document.title = "Create Plan - Remote Mission";
    getFeaturesPermissions([GCS_FEATURES.PLANS]).then(async permissions => {
      addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
      console.log(permissions)
      this.setState({
        permissions,
        activeTeam: await getActiveTeam()
      }, () => {
        if (this.props.location.state) {
          let { user, type, plan, project, center, linked_map } = this.props.location.state;
          this.setState(
            (state) => ({
              ...state,
              user,
              type,
              linked_map: linked_map ? linked_map : plan && plan.linked_map ? plan.linked_map : null,
            }),
            () => {
              let state = this.props.location.state;
              if (state.editPlan) {
                this.setState((prevState) => ({
                  ...prevState,
                  editPlan: true,
                }), () => {
                  this.initData()
                });
                let { projectName, planName } = state;
                this.props.addProjectDetails({
                  projectName: projectName,
                  clientName: "asd",
                  planName: planName,
                  dateAndTime: "",
                });
              } else {
                this.setState((prevState) => ({
                  ...prevState,
                  altitude: state.altitude,
                  speed: state.speed,
                  Distance: state.Distance,
                  flightType: state.flightType,
                  center: state.center,
                  lattitude: state.center.lat,
                  longitude: state.center.lng,
                }), () => {
                  this.initData()
                });
              }
            }
          );
        } else {
          API.getUserType().then(
            ([blah, type, user]) => {
              this.setState(
                (state) => ({
                  ...state,
                  user,
                  type,
                }),
                () => {
                  this.initData()
                }
              );
            },
            (e) => {
              window.location.replace("/login");
            }
          );
        }
      })

    })
      .catch(e => window.location.replace('/'))

    // this.initializeFirstPoint()
  }

  clearPlan2 = () => {
    this.setState((state) => ({
      ...state,
      deleteItem: "WAYPOINT",
      itemName: "All Mission Waypoints",
      processPopupIcon: "WARNING",
      processAction: "DELETE",
      processMessage: null,
      showProcessPopup: true,
    }));
  }

  render() {
    const { waypoints, waypoints1, currentpage, userperpage } = this.state;
    const lastIndex = currentpage * userperpage;
    const firstpage = lastIndex - userperpage;
    const currentuser1 = waypoints1.slice(firstpage, lastIndex);
    const currentuser = waypoints.slice(firstpage, lastIndex);
    const totalpages = waypoints.length / userperpage;
    let { user, type } = this.state;

    return (<>
      <Modal
        style={{ marginTop: "150px" }}
        show={this.state.showMissionPopup}
        onHide={() => {
          this.setState((state) => ({
            ...state,
            showMissionPopup: false,
            creatingPlan: false,
          }));
        }}
      >
        <Modal.Body>
          <p>Are you sure to delete all mission waypoints.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="addcustomer-button-empty-dashboard"
            onClick={() => {
              this.setState((state) => ({
                ...state,
                showMissionPopup: false,
                creatingPlan: false,
              }));
            }}
          >
            cancel
          </button>
          <button
            className="add-user-form-submit-button btn-ok"
            style={{ width: "fit-content" }}
            onClick={this.clearPlan}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ marginTop: "150px" }}
        show={this.state.showFencePopup}
        onHide={() => {
          this.setState((state) => ({
            ...state,
            showFencePopup: false,
            creatingPlan: false,
          }));
        }}
      >
        <Modal.Body>
          <p>Are you sure to delete all fence waypoints.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="addcustomer-button-empty-dashboard"
            onClick={() => {
              this.setState((state) => ({
                ...state,
                showFencePopup: false,
                creatingPlan: false,
              }));
            }}
          >
            cancel
          </button>
          <button
            className="add-user-form-submit-button btn-ok"
            style={{ width: "fit-content" }}
            onClick={this.clearPlan1}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ marginTop: "150px" }}
        show={this.state.showWaypointValidationPopup}
        onHide={() => {
          this.setState((state) => ({
            ...state,
            showWaypointValidationPopup: false,
            creatingPlan: false,
          }));
        }}
      >
        <Modal.Body>
          <p>Mission is outside geofence area.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="add-user-form-submit-button btn-ok"
            style={{ width: "fit-content" }}
            onClick={() => {
              this.setState((state) => ({
                ...state,
                showWaypointValidationPopup: false,
                creatingPlan: false,
              }));
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{ marginTop: "150px" }}
        show={this.state.showWaypointValidationPopup1}
        onHide={() => {
          this.setState((state) => ({
            ...state,
            showWaypointValidationPopup1: false,
            creatingPlan: false,
          }));
        }}
      >
        <Modal.Body>
          <p>Please select geofence area.</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="add-user-form-submit-button btn-ok"
            style={{ width: "fit-content" }}
            onClick={() => {
              this.setState((state) => ({
                ...state,
                showWaypointValidationPopup1: false,
                creatingPlan: false,
              }));
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>

      {/* -------------- Activity Popup ----------------------- */}
      <ActivityPopup
        item={this.state.itemName}
        open={this.state.showProcessPopup}
        icon={this.state.processPopupIcon}
        action={this.state.processAction}
        msg={this.state.processMessage}
        close={() => this.closeActivityPopup()}
        onClickOk={() => this.deleteUserConfirm()}
      />
      {/* --------------------------------------------------------------- */}

      <div className="wrapper">
        {this.successRedirectComponent()}
        {this.redirectToCreatePlan()}

        <div
          className="right-content-new"
          style={{ overflowY: "hidden" }}
          onClick={() => {
            this.setState((state) => ({
              ...state,
              sidebarMode: "shrunk",
            }));
          }}
        >
          <Popup
            trigger={
              <button
                id="send-plan-popup-button"
                style={{
                  display: "none",
                  // width:"30% !important"
                }}
              ></button>
            }
            modal
          >
            <img
              id="close-popup"
              src={closeButton}
              style={{
                width: "auto",
                height: "26px",
                position: "absolute",
                top: "-13px",
                right: "-13px",
                cursor: "pointer",
              }}
              onClick={() => {
                document.getElementById("send-plan-popup-button").click();
              }}
            ></img>
            <div>
              <div style={{ width: "100%", display: "flex" }}>
                <img
                  src={waiting}
                  style={{
                    width: "auto",
                    height: "64px",
                    margin: "30px auto",
                  }}
                />
              </div>
              {this.state.editPlan ? (
                <>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Updating Plan Please Wait...
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "30px",
                    }}
                  >
                    Creating Plan Please Wait...
                  </div>
                </>
              )}
            </div>
          </Popup>
          <div className="top-bar-new">
            <div className="top-bar-text-new">
              <BreadCrumbs
                data={[
                  {
                    displayName: "AeroGCS",
                    pathname: "/",
                    state: {
                      user
                    }
                  },
                  {
                    displayName: 'Flight Plan',
                  }
                ]} />
            </div>
          </div>
          {this.state.loading ? <LoaderComponent /> :
            <div
              className="main-content main-content-dcc"
              style={{
                paddingTop: "1px",
                paddingLeft: "0px",
                paddingRight: "0px",
                display: "flex",
              }}
            >
              {/* -------------- Activity Popup ----------------------- */}
              <ActivityPopup
                item={this.state.itemName}
                open={this.state.showProcessPopup}
                icon={this.state.processPopupIcon}
                action={this.state.processAction}
                msg={this.state.processMessage}
                close={() => this.closeActivityPopup()}
                onClickOk={() => this.state.deleteItem == "FENCE" ? this.clearPlan1() : this.clearPlan()}
              />
              {/* --------------------------------------------------------------- */}
              {/* plan empty modal */}
              <Modal
                style={{ marginTop: "150px" }}
                show={this.state.planEmptyModal}
                onHide={() => {
                  this.setState((state) => ({
                    ...state,
                    planEmptyModal: false,
                  }));
                }}
              >
                <Modal.Body>
                  <p>There is no mission plan, Would you like to create one ?</p>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <button
                      className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                      onClick={() => {
                        this.setState((state) => ({
                          ...state,
                          planEmptyModal: false,
                        }));
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      className="add-user-form-submit-button popup-btn-right btn-ok"
                      onClick={() => {
                        this.setState(
                          (state) => ({
                            ...state,
                            planEmptyModal: false,
                          }), () => {
                            this.drawPlan()
                          }
                        );
                      }}
                    >
                      Create
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
              {/* plan empty modal end */}
              <div
                style={{
                  zIndex: 1,
                  height: "auto",
                  position: "absolute",
                  right: "50px",
                  top: '90px'
                }}
              >
                {this.state.toggle === true ? <div
                  className="page2"
                  style={{ width: "330px", padding: "15px 25px 15px 25px" }}
                >
                  <form
                    onSubmit={this.submitAddUser}
                    action={this.submitAddUser}
                  >
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <div
                        className="font-color-primary"
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        Waypoints
                      </div>
                      <div
                        className="font-color-secondary"
                        style={{
                          marginLeft: "35px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Mission
                      </div>
                      <div
                        className="custom-control custom-switch"
                        style={{ marginLeft: "15px" }}
                      >
                        <input
                          className="input-feild-rm custom-control-input"
                          type="checkbox"
                          id="customSwitches"
                          checked={this.state.toggleChecked}
                          style={{ cursor: 'pointer' }}
                          onChange={() => {
                            if (this.state.waypoints.length > 1) {
                              this.setState({
                                toggleChecked: !this.state.toggleChecked
                              })
                              this.toggleswitch()
                            } else {
                              this.setState({
                                itemName: "Waypoints",
                                processPopupIcon: "ERROR",
                                processAction: "ADD",
                                processMessage: 'Please add two or more waypoints.',
                                showProcessPopup: true,
                              })
                            }
                          }}
                        />
                        <label
                          className="custom-control-label"
                          for="customSwitches"
                        ></label>
                      </div>
                      <div
                        className="font-color-secondary"
                        style={{
                          marginLeft: "10px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Fence
                      </div>
                    </div>
                    <div
                      className="add-user-form-row"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="add-user-form-row-left">
                        <div className="add-user-form-label add-plan-form-label">
                          Lattitude
                        </div>
                        <div className="add-user-form-text-wrapper">
                          <input
                            className="add-user-form-text"
                            disabled={true}
                            value={this.state.lattitude}
                            id="lattitude"
                            type="text"
                            style={{
                              height: "12px",
                              width: "128px",
                            }}
                            placeholder=""
                            onChange={this.onChangeListener}
                          />
                        </div>
                      </div>
                      <div className="add-user-form-row-right">
                        <div className="add-user-form-label add-plan-form-label">
                          Longitude
                        </div>
                        <div className="add-user-form-text-wrapper">
                          <input
                            disabled={true}
                            className="add-user-form-text"
                            value={this.state.longitude}
                            id="longitude"
                            type="text"
                            style={{
                              height: "12px",
                              width: "128px",
                            }}
                            onChange={this.onChangeListener}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="add-user-form-row"
                      style={{ marginTop: "10px" }}
                    >
                      <div className="add-user-form-row-left">
                        <div className="add-user-form-label add-plan-form-label">
                          Altitude
                        </div>
                        <div className="add-user-form-text-wrapper">
                          <input
                            disabled={true}
                            name="Altitude"
                            className="add-user-form-text"
                            type="number"
                            style={{
                              height: "12px",
                              width: "128px",
                            }}
                            value={this.state.altitude}
                          />
                          <div
                            className="units"
                            style={{
                              marginLeft: "7rem",
                              marginTop: "-1.6rem",
                            }}
                          >
                            m
                          </div>
                        </div>
                      </div>
                    </div>


                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                      <ButtonWithIcon
                        text="Reset" width="100px" height="32px"
                        isDisabled
                        onClick={(e) => {
                          this.setState((state) => ({
                            ...state,
                            deleteItem: "FENCE",
                            itemName: "All Fence Waypoints",
                            processPopupIcon: "WARNING",
                            processAction: "DELETE",
                            processMessage: null,
                            showProcessPopup: true,
                          }));
                        }}
                      />

                      <ButtonWithIcon
                        text="Save" isBtnActive width="100px" height="32px"
                        onClick={(e) => this.createPlan()}
                      />
                    </div>
                  </form>
                </div>
                  : <div
                    className="page2"
                    style={{
                      width: "330px",
                      padding: "15px 25px 15px 25px",
                    }}
                  >
                    <form
                      onSubmit={this.submitAddUser}
                      action={this.submitAddUser}
                    >
                      <div style={{ display: "flex", alignItems: 'center' }}>
                        <div
                          className="services-content-title"
                          style={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                          }}
                        >
                          Waypoints
                        </div>
                        <div
                          className="font-color-secondary"
                          style={{
                            marginLeft: "35px",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Mission
                        </div>

                        <div
                          className="custom-control custom-switch"
                          style={{ marginLeft: "15px" }}
                        >
                          <input
                            className="input-feild-rm custom-control-input"
                            type="checkbox"
                            checked={this.state.toggleChecked}
                            id="customSwitches"
                            style={{ cursor: 'pointer' }}
                            onChange={() => {
                              if (this.state.waypoints.length > 1) {
                                this.setState({
                                  toggleChecked: !this.state.toggleChecked
                                })
                                this.toggleswitch()
                              } else {
                                this.setState({
                                  itemName: "Waypoints",
                                  processPopupIcon: "ERROR",
                                  processAction: "ADD",
                                  processMessage: 'Please add two or more waypoints.',
                                  showProcessPopup: true,
                                })
                              }
                            }}
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitches"
                          ></label>
                        </div>
                        <div
                          className="font-color-secondary"
                          style={{
                            marginLeft: "10px",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Fence
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <img
                          src={prev}
                          onClick={this.prevpage}
                          style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "12px",
                            cursor: "pointer",
                          }}
                        ></img>
                        {currentuser.map((waypts, i) => (
                          <div style={this.state.waypointsStyle[waypts]}>
                            <div className="waypoints">
                              <div
                                className="waypts"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.onClickWayPoint(waypts);
                                }}
                                id="waypoint"
                                tabindex="1"
                              // onKeyPress={() => {
                              //   this.deleteWpt(waypts - 1);
                              // }}
                              >
                                {waypts}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div
                          style={{
                            height: "25px",
                            width: "25px",
                            marginTop: "8px",
                            background: "white",
                            borderRadius: "21px",
                            fontSize: "15px",
                            display: "flex",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            className="font-color-primary"
                            id="addCircle"
                            style={{
                              height: "25px",
                              width: "25px",
                              padding: "0px 7px 7px 7px",
                              background: "white",
                              border: "1px solid",
                              borderColor: "hsl(0, 0%, 93%)",
                              outline: "none",
                              borderRadius: "21px",
                              fontSize: "15px",
                              display: "flex",
                              fontFamily: "Poppins",
                              marginLeft: "5px",
                            }}
                            type="submit"
                            onClick={() => {
                              console.log("addcircle called by + button");
                              this.addWayPoint(
                                { latlng: this.state.lastWaypointLocation }
                              );
                            }}
                          >
                            {"+"}
                          </div>
                        </div>
                        <img
                          onClick={this.nextpage}
                          src={next}
                          style={{
                            height: "18px",
                            width: "18px",
                            marginTop: "12px",
                            marginLeft: "auto",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>

                      <div
                        className="add-user-form-row"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="add-user-form-row-left">
                          <div className="add-user-form-label add-plan-form-label">
                            Lattitude
                          </div>
                          <div className="add-user-form-text-wrapper">
                            <input
                              disabled={true}
                              className="add-user-form-text"
                              value={this.state.lattitude}
                              type="text"
                              style={{
                                height: "12px",
                                width: "128px",
                              }}
                              placeholder=""
                              onChange={this.onChangeListener}
                            />
                          </div>
                        </div>
                        <div className="add-user-form-row-right">
                          <div className="add-user-form-label add-plan-form-label">
                            Longitude
                          </div>
                          <div className="add-user-form-text-wrapper">
                            <input
                              disabled={true}
                              className="add-user-form-text"
                              value={this.state.longitude}
                              type="text"
                              style={{
                                height: "12px",
                                width: "128px",
                              }}
                              placeholder=""
                              onChange={this.onChangeListener}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="add-user-form-row"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="add-user-form-row-left">
                          <div className="add-user-form-label add-plan-form-label">
                            Altitude
                          </div>
                          <div className="add-user-form-text-wrapper">
                            <input
                              name="altitude"
                              className="add-user-form-text"
                              type="number"
                              style={{
                                height: "12px",
                                width: "128px",
                              }}
                              value={this.state.altitude}
                              required
                              onChange={(e) => {
                                let val = e.target.value;
                                let t = e.target;
                                if (val < 122 && val > 0) {
                                  let value = parseFloat(val);
                                  latLongList.waypoints[
                                    this.state.currentPoint - 1
                                  ].altitude = value;
                                  this.setState((state) => ({
                                    ...state,
                                    [t.name]: value,
                                  }));
                                } else {
                                  this.setState({
                                    itemName: "Plan",
                                    processPopupIcon: "ERROR",
                                    processAction: "ADD",
                                    processMessage: "please enter mission altitude between 1m to 121m.",
                                    showProcessPopup: true,
                                  })
                                  latLongList.waypoints[
                                    this.state.currentPoint - 1
                                  ].altitude = "";

                                }
                              }}
                            />
                            <div
                              className="units"
                              style={{
                                marginLeft: "7rem",
                                marginTop: "-1.6rem",
                              }}
                            >
                              m
                            </div>
                          </div>
                        </div>
                        <div className="add-user-form-row-right">
                          <div className="add-user-form-label add-plan-form-label">
                            Speed
                          </div>
                          <div className="add-user-form-text-wrapper">
                            <input
                              className="add-user-form-text"
                              name="speed"
                              type="number"
                              style={{
                                height: "12px",
                                width: "128px",
                              }}
                              value={this.state.speed}
                              required
                              onChange={(e) => {
                                let val = Number(e.target.value);
                                let t = e.target;
                                if (val < 21 && val > 0) {
                                  latLongList.waypoints[
                                    this.state.currentPoint - 1
                                  ].speed = val;
                                  this.setState((state) => ({
                                    ...state,
                                    [t.name]: val,
                                  }));
                                } else {
                                  this.setState({
                                    itemName: "Plan",
                                    processPopupIcon: "ERROR",
                                    processAction: "ADD",
                                    processMessage: "please enter mission speed between 1m to 20m.",
                                    showProcessPopup: true,
                                  })
                                  latLongList.waypoints[
                                    this.state.currentPoint - 1
                                  ].speed = "";
                                }
                              }}
                            />
                            <div
                              className="units"
                              style={{ marginTop: "-1.6rem" }}
                            >
                              m/s
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="add-user-form-row"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="add-user-form-row-left">
                          <div className="add-user-form-label add-plan-form-label">
                            Camera Action
                          </div>
                          <div className="add-user-form-text-wrapper">
                            <select
                              name="flightType"
                              className="add-user-form-text"
                              style={{
                                width: "286px",
                                height: "35px",
                                padding: "4px",
                              }}
                              value={this.state.camera}
                              required
                              onChange={(e) => {
                                let value = e.target.value;
                                latLongList.waypoints[
                                  this.state.currentPoint - 1
                                ].camera = value;
                                this.setState((state) => ({
                                  ...state,
                                  camera: value,
                                }));
                              }}
                            >
                              <option value="0">No Change</option>
                              <option value="1">Take Photos(time)</option>
                              <option value="2">
                                Take Photos(distance){" "}
                              </option>
                              <option value="3">Stop taking photos</option>
                              <option value="4">
                                Start Recording video
                              </option>
                              <option value="5">
                                Stop recording video
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div style={{ display: "flex", marginTop: "14px" }}>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <div className="add-user-form-text-wrapper">
                            <input
                              onChange={this.holdChange}
                              className="input-feild add-user-form-text"
                              type="checkbox"
                              id="hold"
                              name="hold"
                              style={{
                                width: "15px",
                                height: "20px",

                                marginTop: "5px",
                              }}
                            />
                          </div>
                          <div
                            className="add-user-form-label add-plan-form-label"
                            style={{ marginTop: "11px" }}
                          >
                            Hold
                          </div>
                        </div>

                        <div
                          className="add-user-form-row"
                          style={{ marginTop: "-10px", marginLeft: "20px" }}
                        >
                          <div
                            className="add-user-form-row-left"
                            style={{ marginLeft: "12px" }}
                          >
                            <div className="add-user-form-label add-plan-form-label">
                              Duration
                            </div>
                            <div className="add-user-form-text-wrapper">
                              <input
                                type="number"
                                disabled={!this.state.hold}
                                defaultValue={this.state.duration}
                                className="add-user-form-text"
                                style={{
                                  height: "12px",
                                  width: "57px",
                                }}
                                placeholder=""
                                onChange={(e) => {
                                  let value = e.target.value;
                                  // if(value==''){
                                  //   return
                                  // }
                                  // else{
                                  latLongList.waypoints[
                                    this.state.currentPoint - 1
                                  ].delaySeconds = e.target.value;
                                  this.setState((state) => ({
                                    ...state,
                                    duration: value,
                                  }));
                                  // }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "20px" }}>
                        <div>
                          <ButtonWithIcon
                            text="Clear" width="100px" height="32px"
                            onClick={() => {
                              this.clearPlan2()
                            }}
                          /></div>

                        <div>
                          <ButtonWithIcon
                            text="Save" width="100px" height="32px" isBtnActive
                            onClick={() => {
                              this.createPlan()
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>}
              </div>

              {/* Layer selection popup */}
              {this.state.linked_map ? <div className='slide' style={{ textAlign: 'center', border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', width: "105px", padding: "4px 12px", bottom: '18px', right: '118px', backgroundColor: 'white', borderRadius: '5px', cursor: 'pointer', zIndex: '1', fontSize: "12px", fontWeight: 500 }}
                onClick={() => {
                  this.setState({ layerSelectionPopup: !this.state.layerSelectionPopup })
                }}>
                {this.state.selectedLayer}
              </div> : <></>}
              {this.state.layerSelectionPopup && this.state.linked_map ?
                <div style={{ position: 'absolute', width: "105px", fontSize: "12px", bottom: '50px', right: '118px', backgroundColor: 'white', borderRadius: '5px', zIndex: '1', padding: "1px", fontWeight: 500 }} className="content-text">
                  <div onClick={() => this.changeSelectedLayer("Orthomosaic")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    Orthomosaic
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("NDVI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    NDVI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("VARI")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    VARI
                  </div>
                  <hr style={{ margin: "3px" }}></hr>
                  <div onClick={() => this.changeSelectedLayer("Base")} style={{ cursor: 'pointer', margin: "3px 4px 3px 12px" }}>
                    BASE
                  </div>
                </div> : <></>}

              {/* changeBaseMapView  */}
              <div className='slide' style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', position: 'absolute', bottom: '18px', right: '18px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '1' }}
                onClick={() => {
                  this.switchBaseLayer()
                }}>
                <img style={{ width: '90px', height: '27px', borderRadius: '4px' }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
              </div>
              <div id="rmp-map" style={{ width: "100%", padding: "0", height: "calc(100vh - 70px)" }}>

                {this.state.permissions && <SingleMap
                  setBaseLayerToState={this.setBaseLayerToState}
                  initCenter={[this.state.center.lat, this.state.center.lng]}
                  initZoom={20}
                  handleMapClick={this.mapClicked}
                  maxZoom={23}
                  setMap={(map) => {
                    this.setState({ map }, this.initMap)
                  }}
                />}
              </div>
            </div>}

        </div>
      </div>
    </>);
  }
}
const mapStateToProps = (state) => {
  return {
    projectName: state.project.projectName,
    clientName: state.project.clientName,
    planName: state.project.planName,
    dateAndTime: state.project.dateAndTime,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addPlan: (payload) => dispatch(addPlan(payload)),
    addProjectDetails: (payload) => dispatch(addProjectDetails(payload)),
  };
};


export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})(connect(mapStateToProps, mapDispatchToProps)(RMFlightPlan));
