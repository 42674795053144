import React, { useEffect, useState } from 'react';
import StatCard from './ReusableComponents/StatCard';
import { Link, Redirect } from 'react-router-dom';

import Loader from './icons/loader.svg';
import DroneIcon from "./assets/Flights.svg";
import AreaIcon from "./assets/Acres.svg";
import DistanceIcon from "./assets/Drones.svg";
import PilotIcon from "./icons/groupAdmin.png";
import AeroStreamServiceActive from './assets/LiveStream.svg'
import TableView from './ReusableComponents/TableView/TableView';
import { getAllAdminStat, getAllDroneLocations, getCropsAndAgrochemicals, getUserType } from './api';
import LoaderComponent from './ReusableComponents/LoaderComponent';
import hourGlass from './icons/hour-glassAdmin.png'
import warning from './icons/warning.png'
import streamsIcon from './icons/aeroStreamServiceActive.png'
import distanceIcon from './icons/distance.png'

const AdminPanel = (props) => {
    const [allStats, setAllStats] = useState(undefined)
    const [user, setUser] = useState(undefined);
    const [cropsAndAgrochemicals, setCropsAndAgrochemicals] = useState([])
    const [cardLoading, setCardLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(true)
    const [drones, setDrones] = useState([])
    const [allDronesCount, setAllDronesCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [tableLoadingCrop, setTableLoadingCrop] = useState(true)

    const isAdmin = true

    const stats = async () => {
        try {
            setCardLoading(true)
            let stats = await getAllAdminStat()
            setAllStats(stats)
            setCardLoading(false)
            setLoading(false)
        } catch (err) {
            setCardLoading(false)
            setLoading(false)
            console.log("error:", err);
        }
    }

    const getLocationHistory = async () => {
        try {
            setTableLoading(true)
            let droneLocations = await getAllDroneLocations(" ", isAdmin, { pageSize: 15, pageNumber: 1 })
            setDrones(droneLocations.drones || [])
            setTableLoading(false)
            setLoading(false)
        } catch (err) {
            setTableLoading(false)
            setLoading(false)
            console.log("error:", err);
        }
    }

    const getCropsAndAgrochemicalsData = async () => {
        try {
            setTableLoadingCrop(true)
            let cropsAndAgrochemicals = await getCropsAndAgrochemicals(undefined, { pageSize: 15, pageNumber: 1, })
            setCropsAndAgrochemicals(cropsAndAgrochemicals.crops)
            setTableLoadingCrop(false)
            setLoading(false)
        } catch (err) {
            setTableLoading(false)
            setLoading(false)
            console.log("error:", err);
        }
    }

    useEffect(() => {
        getUserType().then(([blah, type, user]) => {
            setUser(user);
            stats()
            getLocationHistory()
            getCropsAndAgrochemicalsData()
        }).catch(err => {
            window.location.replace("/login");
        })
    }, [])

    return <div className="wrapper">
        <div className="right-content-new" >
            <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Dashboard</div></div>
            {loading ? <LoaderComponent />
                : <div className="main-content-new" >
                    <div className='stat-cards-container' >
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '100%' }}>
                            <StatCard
                                className={'stat-flights'}
                                leftBorderColor={'#cf3329c2'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={AeroStreamServiceActive} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.totalLiveFlights : 0}
                                statName="Live Drones"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-kilometers'}
                                leftBorderColor={'#EBA303'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "170%", height: '170%' }} src={DistanceIcon} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.totalDrones : 0}
                                statName="Drones"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-flights'}
                                leftBorderColor={'#2989CF'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={DroneIcon} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.flightCount : 0}
                                statName="Flights"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-hours'}
                                leftBorderColor={'#364E48'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={hourGlass} className='flights-animation' />
                                </div>}
                                statCount={allStats && allStats.totalHours ?
                                    Math.round(((allStats.totalHours.days == undefined ? 0 : allStats.totalHours?.days * 24 * 60)
                                        + (allStats.totalHours?.hours == undefined ? 0 : allStats.totalHours?.hours * 60)
                                        + (allStats.totalHours?.minutes == undefined ? 0 : allStats.totalHours?.minutes)
                                        + (allStats.totalHours?.seconds == undefined ? 0 : allStats.totalHours?.seconds / 60)
                                        + (allStats.totalHours?.milliseconds == undefined ? 0 : allStats.totalHours?.milliseconds / 60000)) / 60)
                                    : 0}
                                statName="Hours"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-acres'}
                                leftBorderColor={'#718D4A'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={AreaIcon} className='flights-animation' />
                                </div>}
                                statCount={allStats ? Math.round(Number(allStats.totalAreaCovered) / 4046.85642) : 0}
                                statName="Acres"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                        </div>
                    </div>

                    <div className='stat-cards-container' >
                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', height: '100%', width: '100%' }}>
                            <StatCard
                                className={'stat-flights'}
                                leftBorderColor={'#4285F4'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={streamsIcon} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.streamsCount : 0}
                                statName="Streams"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-livestream'}
                                leftBorderColor={'#cf1f25'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={warning} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.totalDroneErrors : 0}
                                statName="Errors"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-acres'}
                                leftBorderColor={'#45C594'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(66, 133, 244, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} src={distanceIcon} className='flights-animation' />
                                </div>}
                                statCount={allStats ? allStats.totalDistanceCovered : 0}
                                statName="Distance"
                                width={'15vw'}
                                loading={cardLoading}
                            />

                            <StatCard
                                className={'stat-pilots'}
                                leftBorderColor={'#6868B2'}
                                iconContainer={<div className='icon-container' style={{ background: 'rgba(104, 104, 178, 0.1)', }}>
                                    <img style={{ width: "100%", height: '100%' }} className='pilots-animation' src={PilotIcon} />
                                </div>}
                                statCount={allStats ? allStats.usersCount : 0}
                                statName="Users"
                                width={'15vw'}
                                loading={cardLoading}
                            />
                            <div style={{ width: '15vw' }}></div>

                        </div>
                    </div>

                    <div style={{ display: "flex", width: "100%", gap: "30px", marginTop: "20px", maxHeight: "calc(100% - 240px)" }}>
                        <div style={{ width: "50%", background: "white", borderRadius: "7px", boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", }}>
                                <div style={{ color: "#3c3c3c", fontSize: "16px" }}>Location History</div>
                                {<Link
                                    to={{
                                        pathname: "/admin/all_drones_locations",
                                        state: {},
                                    }}
                                >
                                    <span className="view-all">View All</span>
                                </Link>}
                            </div>
                            <div style={{ height: "calc(100% - 60px)" }} >
                                {user &&
                                    <TableView
                                        isLoading={tableLoading}
                                        columns={[
                                            { Header: "Drone Id", accessor: "drone_id", width: '20%' },
                                            { Header: "UIN", accessor: "uin", width: '15%' },
                                            { Header: "Manufacturer", accessor: "username", width: '20%' },
                                            { Header: "Last Cords", accessor: "latLng", width: '15%' },
                                            { Header: "Location", accessor: "location", width: '30%' },
                                        ]}
                                        count={allDronesCount}
                                        data={drones || []}
                                        user={user}
                                        onStateChange={(state) => { }}
                                        onClickRow={(drone) => {
                                            props.history.push('/admin/drone_flight_locations', {
                                                user: user,
                                                drone: drone
                                            });
                                        }}
                                    />}
                            </div>
                        </div>
                        <div style={{ width: "50%", background: "white", borderRadius: "7px", boxShadow: "0px 0px 4px 0px #0000001A" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", }}>
                                <div style={{ color: "#3c3c3c", fontSize: "16px" }}>Crop & Agrochemical</div>
                                {<Link
                                    to={{
                                        pathname: "/admin/cropsAndAgrochemicals",
                                        state: { user },
                                    }}
                                >
                                    <span className="view-all">View All</span>
                                </Link>}
                            </div>
                            <div style={{ height: "calc(100% - 60px)" }} >
                                {<TableView
                                    // showSrNo
                                    isLoading={tableLoadingCrop}
                                    onStateChange={() => { }}
                                    onClickLink={(flight) => { }}
                                    data={cropsAndAgrochemicals != 0 ? cropsAndAgrochemicals : []}
                                    columns={cropsAndAgrochemicals != 0 ? [
                                        { Header: "Drone Id", accessor: "droneid", width: '20%' },
                                        { Header: "Acre", accessor: "total_spray_area", width: '15%' },
                                        { Header: "Crop", accessor: "crop_type", width: '20%' },
                                        { Header: "Agrochemical", accessor: "pesticide", width: '15%' },
                                        { Header: "Litre", accessor: "total_spray_volume", width: '30%' },
                                    ] : []}
                                />}
                            </div>
                        </div>
                    </div>
                </div>}
        </div >
    </div >
}

export default AdminPanel;


