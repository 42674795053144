import React, { Component } from 'react';
import Loader from './icons/loader.svg';
import * as API from './api.js';
import './style.css';
import { connect } from "react-redux";
import { updateSubscriptionData } from './redux'
import Help from "./sidebaricon/Help.png";
import Settings from './sidebaricon/SettingsActive.png';
import Switch from "react-switch";
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import AppConstants from './AppConstants';
import Confetti from 'react-confetti'
import { addUserAnalytic, formatToIndianRupees, Popup, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min.js';
import AeroGCSLogo from "../src/icons/AeroGCSEnterprise.svg"
class AeroMeghPlans extends Component {
    state = {
        user: {},
        loading: true,
        type: "",
        term: "Yearly",
        serviceName: "",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        selectedItems: undefined,
        droneQty: 1,
        prevDroneQuantity: 0,
        droneBuyObjs: { id: "", qty: 1 },
        pendingPayments: [],
        outId: "",
        currentPlan: {}
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.droneQty !== this.state.droneQty) {
            this.buildDroneBuyURL()
        }
    }

    loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: this.state.user.username == 'subscriptiontest@mailinator.com' ? res.plans : res.plans.filter(plan => (plan.plan_name == 'AeroGCS Enterprise Pro' || plan.plan_name == 'AeroGCS Enterprise Business' || plan.plan_name == 'AeroGCS Enterprise Enterprise')),
                    loading: false,
                }), () => {
                })
            } else {
                this.setState((state) => ({
                    ...state,
                    loading: false,
                }))
            }

        }).catch(e => {
            console.log(e)
            alert("Failed to fetch required page data")
            // window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))
        })
    }

    displayRazorpay = async (order, plan) => {
        const res = await this.loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            Popup.alert("ERROR", 'Razropay failed to load!!')
            return
        }
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY, 
            subscription_id: order.id,
            currency: "INR",
            name: "AeroGCS",
            image: AeroGCSLogo,
            handler: async (response) => { 
                const res = await API.verifyRazorPayPayment(response)
                if (res.success) {
                    const res = await API.cancelSubscription(this.state.currentPlan.user_sub_id, false)
                    console.log('cancelSubscription', res);
                    this.props.history.push(`/payment/success?reference=${res.payment_id}`, {
                        message: "Congratulations, your subscription will be activated in few minutes..."
                    })
                } else {
                    this.props.history.push(`/payment/failed?reference=${res.payment_id}`, {
                        message: "Oops, payment failed die to some reason!"
                    })
                }
            },
            notes: {
                payment_type: "plan_subscription_bought",
                db_subscription_plan_id: plan.id,
                service: AppConstants.SERVICES.AEROGCS,
            },
            prefill: {
                email: this.state.user.username,
            },
            readonly: { email: true },
            theme: {
                "color": "#2989cf"
            },
            method: {
                card: true,
                upi: true,
                intent: true,
            },
            config: {
                display: {
                    upi: {
                        mandatory: true, 
                        method: ['auto', 'intent'], 
                    }
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        paymentObject.on('payment.failed', function (response) {
            console.log("Error", response)
        });
    }

    handleCheckout = async (plan) => {
        Popup.alert("WAIT", "Redirecting to payment, please wait...")
        try {
            console.log('plan', plan);

            const data = await API.createOrder(plan);
            if (data.success) {
                //code to open pay now page
                Popup.close()
                this.displayRazorpay(data.order, plan)
            }
        } catch (e) {
            Popup.alert("ERROR", e.message)
        }
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Plans - AeroMegh";
        API.getUserType().then(([blah, type, user]) => {
            addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
            this.setState((state) => ({
                ...state,
                user,
                type,
                serviceName: this.props.location.state?.serviceName,
                currentPlan: this.props.location.state?.currentPlan,
            }), () => {

                const query = new URLSearchParams(window.location.search);
                if (query.get("success") && query.get("session_id")) {
                    const sessionId = query.get("session_id")
                    const planId = query.get('plan_id')
                    if (planId) //subscription payment
                        API.getPaymentDetails(sessionId, AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.SERVICES_PLAN).then(paymentResponse => {
                            this.setState({
                                sessionId,
                                paymentResponse,
                                loading: false
                            }, () => {
                                API.getUserSubscriptionPlan().then((sub) => {
                                    if (sub.plans) {
                                        this.props.updateSubscriptionData({
                                            subscriptionData: sub
                                        })
                                    } else {
                                        this.props.updateSubscriptionData({
                                            subscriptionData: undefined
                                        })
                                    }
                                }).catch(err => {
                                    // this.props.updateSubscriptionData({
                                    //     subscriptionData: undefined
                                    // })
                                    this.setState({ loading: false, errorOccured: { message: err.message, status: true } })
                                })
                            })
                        }).catch(err => {
                            console.log(err)
                            this.setState({ loading: false, errorOccured: { message: err.message, status: true } })
                        })
                    else { //credits payment
                        API.getPaymentDetails(sessionId, AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS).then(paymentResponse => {
                            this.setState({
                                sessionId,
                                paymentResponse,
                                loading: false
                            }, () => {
                            })
                        }).catch(err => {
                            console.log(err)
                            this.setState({ loading: false, errorOccured: { message: err.message, status: true } })

                        })
                    }

                } else if (query.get("cancelled")) {
                    const serviceName = query.get('serviceName')
                    if (serviceName) {
                        this.setState({ serviceName }, () => {
                            this.getAeromeghPlans();
                        })
                    }
                } else {
                    this.getAeromeghPlans();
                }
            })
        }, (e) => {
            window.location.replace("/login");
        })
    }


    render() {
        let { user, type, plans, term, sessionId, paymentResponse } = this.state
        return (
            user.username ?
                <div className="wrapper">

                    {paymentResponse?.paymentDetails?.payment_status === 'paid' && !this.state.errorOccured?.status && <Confetti recycle={false} numberOfPieces={500} />}
                    <div className="right-content-new">
                        <div className="top-bar-new">
                            <div className="top-bar-text-new">Subscription Plans</div>
                        </div>
                        {this.state.loading ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <div className="main-content-new">
                                <div style={{ display: "flex", justifyContent: this.state.serviceName === AppConstants.SERVICES.DRONE_CONTROL_CENTER ? "" : "center", alignItems: "center", height: "85vh", flexDirection: this.state.serviceName === AppConstants.SERVICES.DRONE_CONTROL_CENTER ? "column" : "" }}>

                                    {/* iff error occured anywhere during  or after payment */}
                                    {this.state.errorOccured?.status && <div className="page" style={{ padding: "40px", minHeight: "auto", position: "relative" }}>
                                        <div style={{ textAlign: "center" }}>{this.state.errorOccured.message}</div>
                                        <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                            <Link to={{ pathname: "/profile-settings", state: { ...this.state, view: 2 } }} style={{ color: "#2989cf" }}>Ok</Link>
                                        </div>
                                    </div>}

                                    {/* if payment is paid */}
                                    {paymentResponse && paymentResponse?.paymentDetails?.payment_status === 'paid' &&
                                        <div className="page" style={{ padding: "40px", minHeight: "auto", position: "relative" }}>
                                            <div style={{ textAlign: "center" }}>{paymentResponse?.message}</div>
                                            <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                                <Link to={{ pathname: "/profile-settings", state: { ...this.state, view: 2 } }} style={{ color: "#2989cf" }}>Ok</Link>
                                            </div>
                                        </div>}


                                    {/* if payment failed */}
                                    {paymentResponse && paymentResponse?.paymentDetails?.payment_status !== "paid" &&
                                        <div className="page" style={{ padding: "40px", minHeight: "auto" }}>
                                            <div> We regret to inform you that there was an issue with recent payment transaction on {new Date(paymentResponse.paymentDetails.date_time).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}. Unfortunately, the payment could not be successfully processed.</div>
                                            <div style={{ marginTop: "5px", fontWeight: "500" }}>Payment Details:</div>
                                            <div><span style={{ fontWeight: "500" }}>Payment id:</span> {paymentResponse.paymentDetails.id}</div>
                                            <div><span style={{ fontWeight: "500" }}>Payment Amount:</span> {paymentResponse.paymentDetails.amount}</div>
                                            <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                                                <Link to={{ pathname: "/profile-settings", state: { ...this.state, view: 2 } }} style={{ color: "#2989cf" }}>Ok</Link>
                                            </div>
                                        </div>}


                                    {/* if normal plans */}
                                    {!paymentResponse?.paymentDetails?.payment_status && this.state.serviceName && this.state.serviceName != AppConstants.SERVICES.DRONE_CONTROL_CENTER ?
                                        < div className="page" style={{ padding: "0px", height: "auto", minHeight: "auto" }}>
                                            <div className="rpas-model-info-bottom-section">
                                                <div id="aeromegh-plan-validity-selector-wrapper">
                                                    <div style={{ width: "30%" }}>
                                                        <div>
                                                            <div className="services-content-title">Choose Your Plan</div>
                                                        </div>
                                                    </div>

                                                    <div id="aeromegh-plan-validity-selector" style={{ width: "33%" }}>
                                                        <div id="aeromegh-plan-validity-selector-contents">
                                                            <div style={{ lineHeight: "30px", marginLeft: "auto" }}>Annually</div>
                                                            <Switch
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                onColor={"#666666"}
                                                                offColor={"#2989CF"}
                                                                checked={this.state.term === "Monthly"}
                                                                onChange={(checked) => {
                                                                    this.setState({ term: checked ? "Monthly" : "Yearly" })
                                                                }}
                                                            />
                                                            <div style={{ lineHeight: "30px", marginRight: "0px" }}>Monthly</div>
                                                        </div>

                                                    </div>
                                                    <div style={{ width: "37%", textAlign: "end", display: "flex", justifyContent: "right", alignItems: "center" }}>
                                                        <div><button className="addcustomer-button-empty-dashboard popup-btn-left btn-ok"
                                                            onClick={() => { window.open("http://aerogcs.com/product-pricing/") }}>Compare plans</button></div>
                                                    </div>
                                                </div>

                                                {plans?.length == 0 ? <div style={{ width: '100%', textAlign: 'center', margin: '50px 0px' }}>No data found. It seems you may already be on the highest plan.</div>
                                                    : <div className="aeromegh-plans-tables-wrapper">
                                                        {plans.filter(plan => plan.plan_type === this.state.term && this.state.serviceName === plan.service)
                                                            .sort((a, b) => Number(a.plan_cost) - Number(b.plan_cost))
                                                            .map((plan, key) => {
                                                                return <div key={key} className="aeromegh-plan-table">
                                                                    <div className="aeromegh-plan-table-name">{plan.plan_name}</div>
                                                                    <div className="aeromegh-plan-table-price"> ₹ {plan.plan_cost <= 0 ? '0' : formatToIndianRupees(Number(plan.plan_cost) - (Number(plan.plan_cost) * (Number(plan.discount) / 100))).split('.')[0]}
                                                                        {this.state.term == 'Monthly' ? <span className="font13" style={{ textAlign: "center" }}>/Month</span>
                                                                            : <span className="font13" style={{ textAlign: "center" }}>/Year</span>}
                                                                    </div>
                                                                    <div className="aeromegh-plan-table-detail">{plan.description}</div>
                                                                    <div className="aeromegh-plan-table-price">
                                                                        <button onClick={() => this.handleCheckout(plan)} className='buy-button'
                                                                            style={{ fontSize: "12px", margin: "auto", color: "white", padding: "12px 20px 10px 20px" }} type="submit" >Buy Now</button>
                                                                    </div>
                                                                </div>
                                                            })}
                                                    </div>}
                                            </div>
                                        </div>
                                        : <></>}
                                </div>
                            </div>}
                    </div>
                </div > : <div />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSubscriptionData: (payload) => dispatch(updateSubscriptionData(payload))
    };
};

export default (connect(null, mapDispatchToProps)(withRouter(AeroMeghPlans)));